import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {FaCheckCircle, FaRegThumbsUp } from 'react-icons/fa';
import { Row, Col } from 'react-bootstrap';

// Setup the localizer by providing the moment object to the localizer function
const localizer = momentLocalizer(moment);

const TwoMonthCalendar = ({ events }) => {
  const eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.color, // Use the color property from the event
      borderRadius: '5px',
      opacity: 0.8,
      color: 'white',
      border: '1px solid #ccc',
      display: 'block',
      cursor: 'pointer',
    };

    return {
      style,
    };
  };
  return (
    <>
      

      <Row>
        <Col>
          <Calendar
            localizer={localizer}
            events={events}
            views={['month']}
            defaultView="month"
            startAccessor="start"
            endAccessor="end"
            defaultDate={moment().toDate()}
            eventPropGetter={eventStyleGetter}
            style={{ height: '500px' }}
          />
        </Col>
      </Row>
    </>
    
  );
};

export default TwoMonthCalendar;
