import 'firebase/compat/auth';
import { getDatabase, ref, get, update, set, push, remove, onValue, query, orderByChild, equalTo } from 'firebase/database';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import emailjs from 'emailjs-com';

const database = getDatabase();


export async function addWaiver(waiverName, waiverDescription, questions) {
  const waiversRef = ref(database, 'waivers');
  const newWaiverRef = push(waiversRef);

  // Create the waiver object
  const waiverData = {
    waiverName,
    waiverDescription,
    questions,
  };

  // Save the waiver to the Realtime Database
  await set(newWaiverRef, waiverData);

  console.log('Waiver added to Firebase:', waiverData);
}


export async function addCoupon(code, amount) {
  const couponCodesRef = ref(database, 'portalSettings/settingsID/couponCodes');

  const newWaiverRef = push(couponCodesRef, {coupon: code,discount: amount});

}

export async function updateCoupon(couponId, code, amount) {
  try {
      const couponRef = ref(database, `portalSettings/settingsID/couponCodes/${couponId}`);
      await set(couponRef, { coupon: code, discount: amount });
      console.log('Coupon updated successfully');
  } catch (error) {
      throw new Error('Error updating coupon: ' + error.message);
  }
}

export async function deleteCoupon(couponId) {
  try {
      const couponRef = ref(database, `portalSettings/settingsID/couponCodes/${couponId}`);
      await remove(couponRef);
      console.log('Coupon deleted successfully');
  } catch (error) {
      throw new Error('Error deleting coupon: ' + error.message);
  }
}

export async function getAllCouponCodes() {
  const database = getDatabase();
  const couponCodesRef = ref(database, 'portalSettings/settingsID/couponCodes');

  try {
    const snapshot = await get(couponCodesRef);
    const couponCodes = [];

    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        const coupon = childSnapshot.val();
        couponCodes.push({
          id: childSnapshot.key,
          coupon: coupon.coupon,
          discount: coupon.discount
        });
      });
    }

    return couponCodes;
  } catch (error) {
    console.error('Error getting coupon codes: ', error);
    throw error;
  }
}

export async function getAllBoatTypes() {
  const boatTypesRef = ref(database, 'portalSettings/settingsID/boatTypes');
  try {
      const snapshot = await get(boatTypesRef);
      if (snapshot.exists()) {
          const boatTypesData = [];
          snapshot.forEach((childSnapshot) => {
              const type = childSnapshot.val();
              boatTypesData.push(type);
          });
          return boatTypesData;
      } else {
          console.log('No boat types found');
          return [];
      }
  } catch (error) {
      throw new Error('Error fetching boat types: ' + error.message);
  }
}

export async function getSignedWaiverByID(signedWaiverId) {
  try {
    const allBookings = await getAllBookings();

    var result = false;

    allBookings.forEach(booking => {
      if (booking.tripData) {
        for (const waiverType in booking.tripData.waivers) {
          for (const waiverId in booking.tripData.waivers[waiverType]) {
            if (waiverId == signedWaiverId) {
              const waiver = booking.tripData.waivers[waiverType][waiverId];
              result = waiver;
              return;
            }
          }
        }
      }
    });

    return result;
  } catch (error) {
    throw new Error('Error fetching Booking: ' + error.message);
  }
}



export async function getSpecificWaiverDataAcrossBookings(waiverId) {
  const bookingsRef = ref(database, 'bookings');

  try {
    const snapshot = await get(bookingsRef);
    if (snapshot.exists()) {
      // Iterate over each booking
      snapshot.forEach((bookingSnapshot) => {
        const bookingId = bookingSnapshot.key;

        console.log("bookingId",bookingId);
        const tripDataRef = ref(database, `bookings/${bookingId}/tripData/waivers`);

        get(tripDataRef).then((tripDataSnapshot) => {
          if (tripDataSnapshot.exists()) {
            // Iterate over each waiver type under trip data
            tripDataSnapshot.forEach((waiverTypeSnapshot) => {
              const waiverType = waiverTypeSnapshot.key;
              console.log("waiverType",waiverType);
              const specificWaiverRef = ref(database, `bookings/${bookingId}/tripData/waivers/${waiverType}/`);
              

              get(specificWaiverRef).then((specificWaiverSnapshot) => {
                if (specificWaiverSnapshot.exists()) {
                  console.log("specificWaiverSnapshot",specificWaiverSnapshot);
                  
                }
              }).catch((error) => {
                throw new Error('Error fetching trip data: ' + error.message);
              });
            });
          }
        }).catch((error) => {
          throw new Error('Error fetching trip data: ' + error.message);
        });
      });
  
      return 'no waiver found';
    } else {
      // No bookings found
      return "no bookings";
    }
  } catch (error) {
    throw new Error('Error fetching specific waiver data across bookings: ' + error.message);
  }
}

export async function updateWaiverById(waiverId, updatedWaiverData) {
  const waiverRef = ref(database, `waivers/${waiverId}`);

  // Update the waiver with the new data
  await update(waiverRef, updatedWaiverData);

  console.log('Waiver updated in Firebase:', updatedWaiverData);
}

export async function sendEmail (to, subject, emailBody) {
  try {
    // Replace these values with your own Service ID, Template ID, and User ID
    const serviceId = 'service_z43y28k';
    const templateId = 'template_a8vua0i';
    const userId = 'aj8G924WmTKPdsG8-';

    const response = await emailjs.send(serviceId, templateId, {
      to_email: to,
      email_subject: subject,
      email_body: emailBody,
      }, userId, {
        'Content-Type': 'text/html', // Add this line
    });
    

    console.log('Email sent successfully:', response);
    return true;
  } catch (error) {
    console.error('Email failed to send:', error);
    return false;
  }
}

export async function contactUsEmail (name, email, phone, zipcode, message) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
  <title></title>
  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
    rel="stylesheet" type="text/css" />
  <!--<![endif]-->
  <style>
    * {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      padding: 0;
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important;
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none;
    }

    p {
      line-height: inherit
    }

    .desktop_hide,
    .desktop_hide table {
      mso-hide: all;
      display: none;
      max-height: 0px;
      overflow: hidden;
    }

    .image_block img+div {
      display: none;
    }

    @media (max-width:700px) {
      .desktop_hide table.icons-inner {
        display: inline-block !important;
      }

      .icons-inner {
        text-align: center;
      }

      .icons-inner td {
        margin: 0 auto;
      }

      .mobile_hide {
        display: none;
      }

      .row-content {
        width: 100% !important;
      }

      .stack .column {
        width: 100%;
        display: block;
      }

      .mobile_hide {
        min-height: 0;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        font-size: 0px;
      }

      .desktop_hide,
      .desktop_hide table {
        display: table !important;
        max-height: none !important;
      }

      .row-1 .column-1 .block-3.paragraph_block td.pad>div {
        font-size: 16px !important;
      }

      .row-1 .column-1 .block-2.heading_block h1 {
        font-size: 24px !important;
      }

      .row-1 .column-1 {
        padding: 40px 20px 30px !important;
      }
    }
  </style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
  <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
    <tbody>
      <tr>
        <td>
          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
            role="presentation"
            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
            width="100%">
            <tbody>
              <tr>
                <td>
                  <table align="center" border="0" cellpadding="0" cellspacing="0"
                    class="row-content stack" role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                    width="680">
                    <tbody>
                      <tr>
                        <td class="column column-1"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                          width="100%">
                          <table border="0" cellpadding="0" cellspacing="0"
                            class="icons_block block-1" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                            width="100%">
                            <tr>
                              <td class="pad"
                                style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                <table align="center" cellpadding="0" cellspacing="0"
                                  class="alignment" role="presentation"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td
                                      style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                      <img align="center" class="icon"
                                        height="128"
                                        src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                        style="display: block; height: auto; margin: 0 auto; border: 0;"
                                        width="256" /></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                          <table border="0" cellpadding="10" cellspacing="0"
                            class="heading_block block-2" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                            width="100%">
                          
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0"
                            class="paragraph_block block-3" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                            width="100%">
                            <tr>
                              <td class="pad"
                                style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                <div
                                  style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                  <p style="text-align: center;margin: 0; margin-bottom: 16px;">You have received a submission                                                                  </p>
                                  
                                  <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       BBR Bass Boat Rentals
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">from</p>
                                  <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${name}
                                                                    </span></h1>
                                  <h6 style="text-align: center; margin: 0; margin-top: 16px; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 24px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;">
                                    <span style="display: block; font-size: 26px; text-align: center; margin-top: 32px" class="tinyMce-placeholder">
                                      Submission Details
                                  </span>
                                    <span style="display: block; margin-top: 16px" class="tinyMce-placeholder">
                                        Email: ${email}
                                    </span>
                                    <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                      Phone #: ${phone}
                                  </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Zipcode: ${zipcode}
                                </span>
                                  <span style="display: block; text-align: center; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Message
                                </span>
                                  <span style="display: block; font-size: 19px; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    ${message}
                                </span>
                                </h6>
                                  <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 32px;  color: #ffffff;">
                                                                         <a style=" color: #ffffff;" href="https://BBRBASSBOATRENTALS.COM">BBRBASSBOATRENTALS.COM</a>
</p>
                                  <p style="text-align: center;margin: 0;">Thank you!</p>
                                  <p style="text-align: center;margin: 0;">Best Regards.</p>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table><!-- End -->
</body>

</html>`
  ;
}
export async function adultWaiverEmailBody (bookingTitle, bookingId, passengerName, passengerDob, passengerEmail, captainName, signatureUrl) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
  <title></title>
  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
    rel="stylesheet" type="text/css" />
  <!--<![endif]-->
  <style>
    * {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      padding: 0;
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important;
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none;
    }

    p {
      line-height: inherit
    }

    .desktop_hide,
    .desktop_hide table {
      mso-hide: all;
      display: none;
      max-height: 0px;
      overflow: hidden;
    }

    .image_block img+div {
      display: none;
    }

    @media (max-width:700px) {
      .desktop_hide table.icons-inner {
        display: inline-block !important;
      }

      .icons-inner {
        text-align: center;
      }

      .icons-inner td {
        margin: 0 auto;
      }

      .mobile_hide {
        display: none;
      }

      .row-content {
        width: 100% !important;
      }

      .stack .column {
        width: 100%;
        display: block;
      }

      .mobile_hide {
        min-height: 0;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        font-size: 0px;
      }

      .desktop_hide,
      .desktop_hide table {
        display: table !important;
        max-height: none !important;
      }

      .row-1 .column-1 .block-3.paragraph_block td.pad>div {
        font-size: 16px !important;
      }

      .row-1 .column-1 .block-2.heading_block h1 {
        font-size: 24px !important;
      }

      .row-1 .column-1 {
        padding: 40px 20px 30px !important;
      }
    }
  </style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
  <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
    <tbody>
      <tr>
        <td>
          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
            role="presentation"
            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
            width="100%">
            <tbody>
              <tr>
                <td>
                  <table align="center" border="0" cellpadding="0" cellspacing="0"
                    class="row-content stack" role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                    width="680">
                    <tbody>
                      <tr>
                        <td class="column column-1"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                          width="100%">
                          <table border="0" cellpadding="0" cellspacing="0"
                            class="icons_block block-1" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                            width="100%">
                            <tr>
                              <td class="pad"
                                style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                <table align="center" cellpadding="0" cellspacing="0"
                                  class="alignment" role="presentation"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td
                                      style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                      <img align="center" class="icon"
                                        height="128"
                                        src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                        style="display: block; height: auto; margin: 0 auto; border: 0;"
                                        width="256" /></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                          <table border="0" cellpadding="10" cellspacing="0"
                            class="heading_block block-2" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                            width="100%">
                          
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0"
                            class="paragraph_block block-3" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                            width="100%">
                            <tr>
                              <td class="pad"
                                style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                <div
                                  style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                  <p style="text-align: center;margin: 0; margin-bottom: 16px;">Adult Passenger Waiver has been submitted for the                                                                </p>
                                  
                                  <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       ${bookingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for Booking ID</p>
                                  <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${bookingId}
                                                                    </span></h1>
                                  <h6 style="text-align: center; margin: 0; margin-top: 16px; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 24px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;">
                                    <span style="display: block; text-decoration: underline; font-size: 26px; text-align: center; margin-top: 32px" class="tinyMce-placeholder">
                                      Waiver Details
                                  </span>
                                    <span style="display: block; margin-top: 16px" class="tinyMce-placeholder">
                                        Adult Passenger Name: ${passengerName}
                                    </span>
                                    <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                      Adult Passenger DOB #: ${passengerDob}
                                  </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Adult Passenger Email: ${passengerEmail}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Name of the captain operating the boat: ${captainName}
                                </span>
                                  <span style="display: block; text-align: center; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Signature
                                </span>
                                  <span style="display: block; font-size: 19px; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                 <div style="background: white; padding: 19px; border-radius: 16px;>   
								  <img src='${signatureUrl}' width="240" alt="Signature Image"/>
								  </div>
                                </span>
                                </h6>
                                  <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 32px;  color: #ffffff;">
                                                                         <a style=" color: #ffffff;" href="https://BBRBASSBOATRENTALS.COM">BBRBASSBOATRENTALS.COM</a>
</p>
                                  <p style="text-align: center;margin: 0;">Thank you!</p>
                                  <p style="text-align: center;margin: 0;">Best Regards.</p>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table><!-- End -->
</body>

</html>`
  ;
}
export async function minorWaiverEmailBody (bookingTitle, bookingId, minorName, minorDob, minorAddress, minorCountry, minorCity, minorState, minorZipcode, minorGuardianEmail, minorEmergencyContactName, minorEmergencyContactNumber, insuranceCarrier, insurancePolicyName, captainName, parentName, parentNumber) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
  <title></title>
  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
  <!--[if !mso]><!-->
  <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
    rel="stylesheet" type="text/css" />
  <!--<![endif]-->
  <style>
    * {
      box-sizing: border-box;
    }

    body {
      margin: 0;
      padding: 0;
    }

    a[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: inherit !important;
    }

    #MessageViewBody a {
      color: inherit;
      text-decoration: none;
    }

    p {
      line-height: inherit
    }

    .desktop_hide,
    .desktop_hide table {
      mso-hide: all;
      display: none;
      max-height: 0px;
      overflow: hidden;
    }

    .image_block img+div {
      display: none;
    }

    @media (max-width:700px) {
      .desktop_hide table.icons-inner {
        display: inline-block !important;
      }

      .icons-inner {
        text-align: center;
      }

      .icons-inner td {
        margin: 0 auto;
      }

      .mobile_hide {
        display: none;
      }

      .row-content {
        width: 100% !important;
      }

      .stack .column {
        width: 100%;
        display: block;
      }

      .mobile_hide {
        min-height: 0;
        max-height: 0;
        max-width: 0;
        overflow: hidden;
        font-size: 0px;
      }

      .desktop_hide,
      .desktop_hide table {
        display: table !important;
        max-height: none !important;
      }

      .row-1 .column-1 .block-3.paragraph_block td.pad>div {
        font-size: 16px !important;
      }

      .row-1 .column-1 .block-2.heading_block h1 {
        font-size: 24px !important;
      }

      .row-1 .column-1 {
        padding: 40px 20px 30px !important;
      }
    }
  </style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
  <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
    <tbody>
      <tr>
        <td>
          <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
            role="presentation"
            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
            width="100%">
            <tbody>
              <tr>
                <td>
                  <table align="center" border="0" cellpadding="0" cellspacing="0"
                    class="row-content stack" role="presentation"
                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                    width="680">
                    <tbody>
                      <tr>
                        <td class="column column-1"
                          style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                          width="100%">
                          <table border="0" cellpadding="0" cellspacing="0"
                            class="icons_block block-1" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                            width="100%">
                            <tr>
                              <td class="pad"
                                style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                <table align="center" cellpadding="0" cellspacing="0"
                                  class="alignment" role="presentation"
                                  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                  <tr>
                                    <td
                                      style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                      <img align="center" class="icon"
                                        height="128"
                                        src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                        style="display: block; height: auto; margin: 0 auto; border: 0;"
                                        width="256" /></td>
                                  </tr>
                                </table>
                              </td>
                            </tr>
                          </table>
                          <table border="0" cellpadding="10" cellspacing="0"
                            class="heading_block block-2" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                            width="100%">
                          
                          </table>
                          <table border="0" cellpadding="0" cellspacing="0"
                            class="paragraph_block block-3" role="presentation"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                            width="100%">
                            <tr>
                              <td class="pad"
                                style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                <div
                                  style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                  <p style="text-align: center;margin: 0; margin-bottom: 16px;">Minor Passenger Waiver has been submitted for the                                                                </p>
                                  
                                  <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       ${bookingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for Booking ID</p>
                                  <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${bookingId}
                                                                    </span></h1>
                                  <h6 style="text-align: center; margin: 0; margin-top: 16px; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 24px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;">
                                    <span style="display: block; font-size: 26px; text-align: center; margin-top: 32px" class="tinyMce-placeholder">
                                      Waiver Details
                                  </span>
                                    <span style="display: block; margin-top: 16px" class="tinyMce-placeholder">
                                        Minor's Name: ${minorName}
                                    </span>
                                    <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                      Minor's DOB #: ${minorDob}
                                  </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Address: ${minorAddress}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Country: ${minorCountry}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    City: ${minorCity}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    State: ${minorState}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Zip/Postal: ${minorZipcode}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Parent or Guardian's Email: ${minorGuardianEmail}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Emergency Contact Name: ${minorEmergencyContactName}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Emergency Contact Number: ${minorEmergencyContactNumber}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Insurance Carrier: ${insuranceCarrier}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Insurance Policy Name: ${insurancePolicyName}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Name of the captain operating the boat: ${captainName}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Parent or Guardian's Name: ${parentName}
                                </span>
                                  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
                                    Parent or Guardian's Number: ${parentNumber}
                                </span>
                                </h6>
                                  <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 32px;  color: #ffffff;">
                                                                         <a style=" color: #ffffff;" href="https://BBRBASSBOATRENTALS.COM">BBRBASSBOATRENTALS.COM</a>
</p>
                                  <p style="text-align: center;margin: 0;">Thank you!</p>
                                  <p style="text-align: center;margin: 0;">Best Regards.</p>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table><!-- End -->
</body>

</html>`
  ;
}

// export async function welcomeEmailBody (name, email) {
//   return `
//   <!DOCTYPE html>

//   <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
//   <head>
// 	<title></title>
// 	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
// 	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
// 	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
// 	<!--[if !mso]><!-->
// 	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
// 	  rel="stylesheet" type="text/css" />
// 	<!--<![endif]-->
// 	<style>
// 	  * {
// 		box-sizing: border-box;
// 	  }
  
// 	  body {
// 		margin: 0;
// 		padding: 0;
// 	  }
  
// 	  a[x-apple-data-detectors] {
// 		color: inherit !important;
// 		text-decoration: inherit !important;
// 	  }
  
// 	  #MessageViewBody a {
// 		color: inherit;
// 		text-decoration: none;
// 	  }
  
// 	  p {
// 		line-height: inherit
// 	  }
  
// 	  .desktop_hide,
// 	  .desktop_hide table {
// 		mso-hide: all;
// 		display: none;
// 		max-height: 0px;
// 		overflow: hidden;
// 	  }
  
// 	  .image_block img+div {
// 		display: none;
// 	  }
  
// 	  @media (max-width:700px) {
// 		.desktop_hide table.icons-inner {
// 		  display: inline-block !important;
// 		}
  
// 		.icons-inner {
// 		  text-align: center;
// 		}
  
// 		.icons-inner td {
// 		  margin: 0 auto;
// 		}
  
// 		.mobile_hide {
// 		  display: none;
// 		}
  
// 		.row-content {
// 		  width: 100% !important;
// 		}
  
// 		.stack .column {
// 		  width: 100%;
// 		  display: block;
// 		}
  
// 		.mobile_hide {
// 		  min-height: 0;
// 		  max-height: 0;
// 		  max-width: 0;
// 		  overflow: hidden;
// 		  font-size: 0px;
// 		}
  
// 		.desktop_hide,
// 		.desktop_hide table {
// 		  display: table !important;
// 		  max-height: none !important;
// 		}
  
// 		.row-1 .column-1 .block-3.paragraph_block td.pad>div {
// 		  font-size: 16px !important;
// 		}
  
// 		.row-1 .column-1 .block-2.heading_block h1 {
// 		  font-size: 24px !important;
// 		}
  
// 		.row-1 .column-1 {
// 		  padding: 40px 20px 30px !important;
// 		}
// 	  }
// 	</style>
//   </head>
  
//   <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
// 	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
// 	  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
// 	  <tbody>
// 		<tr>
// 		  <td>
// 			<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
// 			  role="presentation"
// 			  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
// 			  width="100%">
// 			  <tbody>
// 				<tr>
// 				  <td>
// 					<table align="center" border="0" cellpadding="0" cellspacing="0"
// 					  class="row-content stack" role="presentation"
// 					  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
// 					  width="680">
// 					  <tbody>
// 						<tr>
// 						  <td class="column column-1"
// 							style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
// 							width="100%">
// 							<table border="0" cellpadding="0" cellspacing="0"
// 							  class="icons_block block-1" role="presentation"
// 							  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
// 							  width="100%">
// 							  <tr>
// 								<td class="pad"
// 								  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
// 								  <table align="center" cellpadding="0" cellspacing="0"
// 									class="alignment" role="presentation"
// 									style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
// 									<tr>
// 									  <td
// 										style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
// 										<img align="center" class="icon"
// 										  height="128"
// 										  src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
// 										  style="display: block; height: auto; margin: 0 auto; border: 0;"
// 										  width="256" /></td>
// 									</tr>
// 								  </table>
// 								</td>
// 							  </tr>
// 							</table>
// 							<table border="0" cellpadding="10" cellspacing="0"
// 							  class="heading_block block-2" role="presentation"
// 							  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
// 							  width="100%">
							
// 							</table>
// 							<table border="0" cellpadding="0" cellspacing="0"
// 							  class="paragraph_block block-3" role="presentation"
// 							  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
// 							  width="100%">
// 							  <tr>
// 								<td class="pad"
// 								  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
// 								  <div
// 									style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
// 									<p style="text-align: center;margin: 0; margin-bottom: 16px;">Your account has been created on                                                                    </p>
									
// 									<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
// 																		 BBR Bass Boat Rentals
// 																	  </span></h1>
// 																	  <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">under the name</p>
// 									<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
// 																		  ${name}
// 																	  </span></h1>
// 									<h6 style="text-align: center; margin: 0; margin-top: 16px; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 24px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;">
// 									  <span style="display: block; font-size: 26px; text-align: center; margin-top: 32px" class="tinyMce-placeholder">
// 										Credentials
// 									</span>
// 									  <span style="display: block; margin-top: 16px" class="tinyMce-placeholder">
// 										  Email: ${email}
// 									  </span>
// 									  <span style="display: block; margin-top: 8px; margin-bottom: 16px;" class="tinyMce-placeholder">
// 										Password: bbrbassboatrentals
// 									</span></h6>
// 									<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
// 																		  Please log in and change your password from the portal. <a style=" color: #ffffff;" href="https://BBRBASSBOATRENTALS.COM">BBRBASSBOATRENTALS.COM</a>
//   </p>
// 									<p style="text-align: center;margin: 0;">Thank you!</p>
// 									<p style="text-align: center;margin: 0;">Best Regards.</p>
// 								  </div>
// 								</td>
// 							  </tr>
// 							</table>
// 						  </td>
// 						</tr>
// 					  </tbody>
// 					</table>
// 				  </td>
// 				</tr>
// 			  </tbody>
// 			</table>
// 		  </td>
// 		</tr>
// 	  </tbody>
// 	</table><!-- End -->
//   </body>
  
//   </html>
//   `;
// }

export async function welcomeEmailBody (name) {
  return `
  <!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                            
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <p style="text-align: center;margin: 0; margin-bottom: 16px;">Your account has been registered on BBR Bass Boat Rentals</p>
                                    
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                         Account Registered
                                                                      </span></h1>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          ${name}
                                                                      </span></h1>
                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">  </p>
                                    <p style="text-align: center;margin: 0;">Thank you!</p>
                                    <p style="text-align: center;margin: 0;">Best Regards.</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>
  `;
}


export async function getPlaceReviews() {
  try{
    const placeId = 'ChIJc-0iKkIjR4YRwmBh60kNnzQ'; // Replace with the actual place ID
    const response = await fetch(`https://www.bbrbassboatrentals.com/get-place-reviews/${placeId}`);
    const data = await response.json();
    return data.reviews;

  }catch (error) {
    console.error(error);
  }
}

export function sendSMS(to, message) {
  return fetch('https://www.bbrbassboatrentals.com/send-sms', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ to, message })
  })
  .then(response => {
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return response.json();
  })
  .then(data => {
    console.log('SMS sent successfully:', data);
    return data;
  })
  .catch(error => {
    console.error('Failed to send SMS:', error);
    throw error;
  });
}

export async function welcomeOwnerEmailBody (name) {
  return `
  <!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad">
                                  <h1
                                    style="margin: 0; color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;">
                                    <span class="tinyMce-placeholder">Hi ${name},</span></h1>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <p style="margin: 0; margin-bottom: 16px;">Welcome to BBR, home of the tournament ready bass boat!</p>
                                    <p style="margin: 0; margin-bottom: 16px;">Thank you for taking the time to sign-up as a boat owner. </p>
                                    <p style="margin: 0; margin-bottom: 16px;">We can’t wait to get you connected with our pool of verified boat operators. Now that you’re registered as a boat owner with BBR, we recommend you explore our platform to become familiar with the features and take a moment to really showcase your boat with detail descriptions, pictures and video to make the most of our boat rental opportunities. Please check our <a href="https://www.bbrbassboatrentals.com/faq" style="color: #fff;" target="_blank">FAQ</a> section and become familiar with our policies and procedures and liability waivers. If you have any questions or need assistance, our dedicated support team is here for you.</p>
                                    <p style="margin: 0; margin-bottom: 16px;"> </p>
                                    <p style="margin: 0; margin-bottom: 16px;">Happy
                                      sailing!</p>
                                    <p style="margin: 0;">Best Regards.</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>
  `;
}

export async function newListingAddedAdminEmailBody(ownerName, bbrId, listingName){
	return `
	<!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad">
                                  <h1
                                    style="margin: 0; color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;">
                                    <span class="tinyMce-placeholder">Hi Admin,</span></h1>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <p style="margin: 0; margin-bottom: 16px;">${ownerName} - ${bbrId} just added new Bass Boat Listing:</p>
                                    <p style="margin: 0; margin-bottom: 16px;">Listing Name: ${listingName}</p>
                                    <p style="margin: 0; margin-bottom: 16px;">Please login into your admin account to activate listing.</p>
                                    <p style="margin: 0; margin-bottom: 16px;"> </p>
                                    <p style="margin: 0;">Best Regards.</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>
	`;
}

export async function newUserAddedAdminEmailBody(userRole, bbrId, name, email) {
	return `<!DOCTYPE html>

	<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
	
	<head>
	  <title></title>
	  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
	  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	  <!--[if !mso]><!-->
	  <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	  <!--<![endif]-->
	  <style>
		* {
		  box-sizing: border-box;
		}
	
		body {
		  margin: 0;
		  padding: 0;
		}
	
		a[x-apple-data-detectors] {
		  color: inherit !important;
		  text-decoration: inherit !important;
		}
	
		#MessageViewBody a {
		  color: inherit;
		  text-decoration: none;
		}
	
		p {
		  line-height: inherit
		}
	
		.desktop_hide,
		.desktop_hide table {
		  mso-hide: all;
		  display: none;
		  max-height: 0px;
		  overflow: hidden;
		}
	
		.image_block img+div {
		  display: none;
		}
	
		@media (max-width:700px) {
		  .desktop_hide table.icons-inner {
			display: inline-block !important;
		  }
	
		  .icons-inner {
			text-align: center;
		  }
	
		  .icons-inner td {
			margin: 0 auto;
		  }
	
		  .mobile_hide {
			display: none;
		  }
	
		  .row-content {
			width: 100% !important;
		  }
	
		  .stack .column {
			width: 100%;
			display: block;
		  }
	
		  .mobile_hide {
			min-height: 0;
			max-height: 0;
			max-width: 0;
			overflow: hidden;
			font-size: 0px;
		  }
	
		  .desktop_hide,
		  .desktop_hide table {
			display: table !important;
			max-height: none !important;
		  }
	
		  .row-1 .column-1 .block-3.paragraph_block td.pad>div {
			font-size: 16px !important;
		  }
	
		  .row-1 .column-1 .block-2.heading_block h1 {
			font-size: 24px !important;
		  }
	
		  .row-1 .column-1 {
			padding: 40px 20px 30px !important;
		  }
		}
	  </style>
	</head>
	
	<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	  <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
		  <tr>
			<td>
			  <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
				role="presentation"
				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
				width="100%">
				<tbody>
				  <tr>
					<td>
					  <table align="center" border="0" cellpadding="0" cellspacing="0"
						class="row-content stack" role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
						width="680">
						<tbody>
						  <tr>
							<td class="column column-1"
							  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
							  width="100%">
							  <table border="0" cellpadding="0" cellspacing="0"
								class="icons_block block-1" role="presentation"
								style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
								width="100%">
								<tr>
								  <td class="pad"
									style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
									<table align="center" cellpadding="0" cellspacing="0"
									  class="alignment" role="presentation"
									  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
									  <tr>
										<td
										  style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
										  <img align="center" class="icon"
											height="128"
											src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
											style="display: block; height: auto; margin: 0 auto; border: 0;"
											width="256" /></td>
									  </tr>
									</table>
								  </td>
								</tr>
							  </table>
							  <table border="0" cellpadding="10" cellspacing="0"
								class="heading_block block-2" role="presentation"
								style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
								width="100%">
								<tr>
								  <td class="pad">
									<h1
									  style="margin: 0; color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: left; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;">
									  <span class="tinyMce-placeholder">Hi Admin,</span></h1>
								  </td>
								</tr>
							  </table>
							  <table border="0" cellpadding="0" cellspacing="0"
								class="paragraph_block block-3" role="presentation"
								style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
								width="100%">
								<tr>
								  <td class="pad"
									style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
									<div
									  style="color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
									  <p style="margin: 0; margin-bottom: 16px;">New ${userRole} just signed up</p>
									  <p style="margin: 0; margin-bottom: 16px;">BBR ID: ${bbrId}</p>
									  <p style="margin: 0; margin-bottom: 16px;">Name: ${name}</p>
									  <p style="margin: 0; margin-bottom: 16px;">Email: ${email}</p>
									  <p style="margin: 0; margin-bottom: 16px;"> </p>
									  <p style="margin: 0;">Best Regards.</p>
									</div>
								  </td>
								</tr>
							  </table>
							</td>
						  </tr>
						</tbody>
					  </table>
					</td>
				  </tr>
				</tbody>
			  </table>
			</td>
		  </tr>
		</tbody>
	  </table><!-- End -->
	</body>
	
	</html>`;
}

export async function clientVerificationEmail(name) {
	return `<!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                            
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <p style="text-align: center;margin: 0; margin-bottom: 16px;">Your application has been submitted and copies of                                                                     </p>
                                    
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                         RENTER VERIFICATION
                                                                      </span></h1>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          ${name}
                                                                      </span></h1>
                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                          has been sent to BBR for approval, we will contact you shortly!
  If you have not heard back from us within 24 hours please send a follow up email to <a style=" color: #ffffff;" href="mailto:waivers@bbrbassboatrentals.com">waivers@bbrbassboatrentals.com</a>
  </p>
                                    <p style="text-align: center;margin: 0;">Thank you!</p>
                                    <p style="text-align: center;margin: 0;">Best Regards.</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>`;
}
export async function adminVerificationEmail(name) {
	return `<!DOCTYPE html>

	<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
	
	<head>
	  <title></title>
	  <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	  <meta content="width=device-width, initial-scale=1.0" name="viewport" />
	  <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	  <!--[if !mso]><!-->
	  <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	  <!--<![endif]-->
	  <style>
		* {
		  box-sizing: border-box;
		}
	
		body {
		  margin: 0;
		  padding: 0;
		}
	
		a[x-apple-data-detectors] {
		  color: inherit !important;
		  text-decoration: inherit !important;
		}
	
		#MessageViewBody a {
		  color: inherit;
		  text-decoration: none;
		}
	
		p {
		  line-height: inherit
		}
	
		.desktop_hide,
		.desktop_hide table {
		  mso-hide: all;
		  display: none;
		  max-height: 0px;
		  overflow: hidden;
		}
	
		.image_block img+div {
		  display: none;
		}
	
		@media (max-width:700px) {
		  .desktop_hide table.icons-inner {
			display: inline-block !important;
		  }
	
		  .icons-inner {
			text-align: center;
		  }
	
		  .icons-inner td {
			margin: 0 auto;
		  }
	
		  .mobile_hide {
			display: none;
		  }
	
		  .row-content {
			width: 100% !important;
		  }
	
		  .stack .column {
			width: 100%;
			display: block;
		  }
	
		  .mobile_hide {
			min-height: 0;
			max-height: 0;
			max-width: 0;
			overflow: hidden;
			font-size: 0px;
		  }
	
		  .desktop_hide,
		  .desktop_hide table {
			display: table !important;
			max-height: none !important;
		  }
	
		  .row-1 .column-1 .block-3.paragraph_block td.pad>div {
			font-size: 16px !important;
		  }
	
		  .row-1 .column-1 .block-2.heading_block h1 {
			font-size: 24px !important;
		  }
	
		  .row-1 .column-1 {
			padding: 40px 20px 30px !important;
		  }
		}
	  </style>
	</head>
	
	<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	  <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
		  <tr>
			<td>
			  <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
				role="presentation"
				style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
				width="100%">
				<tbody>
				  <tr>
					<td>
					  <table align="center" border="0" cellpadding="0" cellspacing="0"
						class="row-content stack" role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
						width="680">
						<tbody>
						  <tr>
							<td class="column column-1"
							  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
							  width="100%">
							  <table border="0" cellpadding="0" cellspacing="0"
								class="icons_block block-1" role="presentation"
								style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
								width="100%">
								<tr>
								  <td class="pad"
									style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
									<table align="center" cellpadding="0" cellspacing="0"
									  class="alignment" role="presentation"
									  style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
									  <tr>
										<td
										  style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
										  <img align="center" class="icon"
											height="128"
											src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
											style="display: block; height: auto; margin: 0 auto; border: 0;"
											width="256" /></td>
									  </tr>
									</table>
								  </td>
								</tr>
							  </table>
							  <table border="0" cellpadding="10" cellspacing="0"
								class="heading_block block-2" role="presentation"
								style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
								width="100%">
								<tr>
								  <td class="pad">
									<h1
									  style="text-align: center; margin: 0; color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;">
									  Hi Admin,</h1>
								  </td>
								</tr>
							  </table>
							  <table border="0" cellpadding="0" cellspacing="0"
								class="paragraph_block block-3" role="presentation"
								style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
								width="100%">
								<tr>
								  <td class="pad"
									style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
									<div
									  style="color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
									  <p style="text-align: center; margin: 0; margin-bottom: 16px;">${name} has submitted a verification request!</p>
                    <p style="text-align: center; margin: 0; margin-bottom: 16px;">Please login into your admin account to verify user</p>
									  <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
									  </p>
									</div>
								  </td>
								</tr>
							  </table>
							</td>
						  </tr>
						</tbody>
					  </table>
					</td>
				  </tr>
				</tbody>
			  </table>
			</td>
		  </tr>
		</tbody>
	  </table><!-- End -->
	</body>
	
	</html>`;
}



export async function RenterVerificationApprovedEmailBody (name, url) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        CONGRATULATIONS!<br> RENTER VERIFICATION
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${name}
                                                                    </span></h1>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        has been approved, your account has been updated accordingly.
                                                                        On behalf of the entire team here at BBR, 
                                                                        <br>"Welcome and have fun out there!"                                                                        
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0; font-size: 16px;">If you need a signed copy of this waiver for your records you may download it <a style="color: #ffffff;" href="${url}" target="_blank">here</a>.</p>
																	<p style="text-align: center;margin: 0;">Thank you!</p>
																	<p style="text-align: center;margin: 0;">Best Regards.</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function RenterVerificationRejectedEmailBody (name, url, reason) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
                                                                style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                                                <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Thank you for taking the time to complete the renter verification form. Unfortunately the</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        RENTER VERIFICATION
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${name}
                                                                    </span></h1>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        has not been approved. Your account has been updated accordingly. 

                                                                    </p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        The following list of reasons were given to establish this decision: ${reason}                                                                  
                                                                    </p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        The BBR team are all bass fishing enthusiasts who would like to get you on the water safely. If you believe an error was made on the application process, and you would like us to challenge the decision by providing additional or missing information, please contact a member of our team at the following email address <a href="mailto:waivers@bbrbassboatrentals.com" target="_blank" style="color: #ffffff;">waivers@bbrbassboatrentals.com</a>
                                                                    </p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        Although your verification has not yet been approved, you are still eligible to request a rental with a qualified boat captain, or you can contact boat owners through the booking portal to inquire if they are willing to accept your non-approved verification.

On behalf of the entire team here at BBR, we appreciate your business, 
<br>"Welcome to BBR and have fun out there!"

                                                                    </p>
                                                                    <p style="text-align: center;margin: 0; font-size: 16px;">If you need a signed copy of this waiver for your records you may download it <a style="color: #ffffff;" href="${url}" target="_blank">here</a>.</p>
																	<p style="text-align: center;margin: 0;">Thank you!</p>
																	<p style="text-align: center;margin: 0;">Best Regards.</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function OwnerVerificationApprovedEmailBody (name, url) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        CONGRATULATIONS!<br> BOAT OWNER VERIFICATION
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${name}
                                                                    </span></h1>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        has been approved, your account has been updated accordingly.
                                                                        On behalf of the entire team here at BBR, 
                                                                        <br>"Welcome and have fun out there!"                                                                        
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0; font-size: 16px;">If you need a signed copy of this waiver for your records you may download it <a style="color: #ffffff;" href="${url}" target="_blank">here</a>.</p>
																	<p style="text-align: center;margin: 0;">Thank you!</p>
																	<p style="text-align: center;margin: 0;">Best Regards.</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function OwnerVerificationRejectedEmailBody (name, url, reason) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
                                                                style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                                                <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Thank you for taking the time to complete the Boat Owner verification form. Unfortunately the</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        BOAT OWNER VERIFICATION
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${name}
                                                                    </span></h1>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        has not been approved. Your account has been updated accordingly. 

                                                                    </p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        The following list of reasons were given to establish this decision: ${reason}                                                                  
                                                                    </p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                        If you believe an error was made on the application process, and you would like us to challenge the decision by providing additional or missing information, please contact a member of our team at the following email address <a href="mailto:waivers@bbrbassboatrentals.com" target="_blank" style="color: #ffffff;">waivers@bbrbassboatrentals.com</a>
                                                                    </p>
                                                                   
                                                                    <p style="text-align: center;margin: 0; font-size: 16px;">If you need a signed copy of this waiver for your records you may download it <a style="color: #ffffff;" href="${url}" target="_blank">here</a>.</p>
																	<p style="text-align: center;margin: 0;">Thank you!</p>
																	<p style="text-align: center;margin: 0;">Best Regards.</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function InquirySentEmailBody (boatOwnerName, listingTitle) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       CONGRATULATONS INQUIRY SENT!
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Your inquiry has been successfully sent to</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${boatOwnerName} <sub style="font-size: 20px;">Boat Owner</sub>
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${listingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		Kindly await the boat owner's response, as they will get back to you with an offer. If you have any further questions or concerns, please feel free to reach out.<br>
																		<br>On behalf of the entire team here at BBR,                                                                   
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function InquiryReceivedEmailBody (renterName, listingTitle) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       NEW INQUIRY RECEIVED
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">from</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${renterName}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${listingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		On behalf of the entire team here at BBR,                                                                      
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function NewOfferRecievedEmailBody (ownerName, listingTitle) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       NEW OFFER RECEIVED
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">from</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${ownerName}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${listingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		On behalf of the entire team here at BBR,                                                                     
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function sendWaiverBody (waiverName, url) {
  return `
  <!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }

      .btn:hover{
        background-color: #e7e7e7 !important;
      }
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                            
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <p style="text-align: center;margin: 0; margin-bottom: 16px;">You have been requested to submit  the                                                                     </p>
                                    
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                         ${waiverName} 
                                                                      </span></h1>
                                     <h1 style="  text-align: center; margin-top: 40px; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center;  mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                       <a target="_blank" class="btn" style="background-color:#fff;  padding: 6px 26px; border-radius: 8px; color: #000000; text-decoration: none" href="${url}">OPEN WAIVER </a>
                                    </span></h1>                       
                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 32px;  color: #ffffff;">
                                                                          <a style=" color: #ffffff;" href="mailto:waivers@bbrbassboatrentals.com">waivers@bbrbassboatrentals.com</a>
  </p>
                                    <p style="text-align: center;margin: 0;">Thank you!</p>
                                    <p style="text-align: center;margin: 0;">Best Regards.</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>
  `;
}

export async function OfferAccepetedRenterEmailBody (listingTitle) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       CONGRATULATIONS OFFER ACCEPTED!
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">You've accepeted offer for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${listingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">To finalize your booking, kindly proceed with the payment.																	</p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		On behalf of the entire team here at BBR,                                                                       
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function OfferAccepetedOwnerEmailBody (listingTitle, renterName) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       CONGRATULATIONS!
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Your offer for</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${listingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">has been accepted by</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${renterName}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		On behalf of the entire team here at BBR,                                                                     
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function BookingStatusUpdateEmailBody (userName, bookingTitle, status) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        Hi ${userName}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">your booking:</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${bookingTitle}
                                                                    </span></h1>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		is ${status}.<br>
                                                                        On behalf of the entire team here at BBR,                                                                     
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}
export async function proMemberStatusBody (userName, status, referralCode) {
  return `
  <!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                            
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          Hi ${userName}
                                                                      </span></h1>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Your Pro Member Application has been ${status}, your referral code is:</p>
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          ${referralCode}
                                                                      </span></h1>
                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                          On behalf of the entire team here at BBR,                                                                     
                                                                      </p>
                                                                      <p style="text-align: center;margin: 0;">Thank you!</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>
  `;
}

export async function proMemberRejectedStatusBody (userName, status) {
  return `
  <!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                            
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          Hi ${userName}
                                                                      </span></h1>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Your Pro Member Application has been</p>
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          ${status}
                                                                      </span></h1>
                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                                                          On behalf of the entire team here at BBR,                                                                       
                                                                      </p>
                                                                      <p style="text-align: center;margin: 0;">Thank you!</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>
  `;
}

export async function becomeProMemberRequestSentEmailBody (userName) {
  return `
  <!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                            
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          Hi ${userName}
                                                                      </span></h1>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Thank you for submitting your request to become a pro staff member. We are currently in the process of verifying your answers.</p>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Please note that due to the high volume of requests, this may take some time.</p>
                                                                      <p style="text-align: center;margin: 0;">Thank you!</p>
                                    <p style="text-align: center;margin: 0;">Best Regards.</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>`;
}

export async function becomeProMemberRequestAdminEmailBody (userName) {
  return `
  <!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                            
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          Hi Admin
                                                                      </span></h1>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">${userName} has expressed interest in becoming a pro staff member.</p>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Please review pending pro staff requests from your dashboard.</p>
                                                                      <p style="text-align: center;margin: 0;">Thank you!</p>
                                    <p style="text-align: center;margin: 0;">Best Regards.</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>`;
}


export async function BookingCompletedWithDisputeOwnerEmailBody (boatOwner, bookingTitle, amount) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       Hi ${boatOwner}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">your booking</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${bookingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">is now completed, with dispute.</p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Security Deposit: $800</p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Amount Charged: $${amount}</p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">If you believe an error was made on the dispute process, and you would like us to challenge the decision by providing additional or missing information, please contact a member of our team at the following email address <a style="color: #fff;" href="mailto:HELP@BBRBASSBOATRENTALS.COM">HELP@BBRBASSBOATRENTALS.COM</a></p>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		On behalf of the entire team here at BBR,                                                                        
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function BookingCompletedWithDisputeRenterEmailBody (userName, bookingTitle, amount) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                       Hi ${userName}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">your booking</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${bookingTitle}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">is now completed, boat owner decided to file dispute.</p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Security Deposit: $800</p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">Amount Charged: $${amount}</p>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">If you believe an error was made on the dispute process, and you would like us to challenge the decision by providing additional or missing information, please contact a member of our team at the following email address <a style="color: #fff;" href="mailto:HELP@BBRBASSBOATRENTALS.COM">HELP@BBRBASSBOATRENTALS.COM</a></p>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		On behalf of the entire team here at BBR,                                                                       
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function WaiverSignedEmailBody (waiverName, filerName, url, submissionID) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        CONGRATULATIONS!<br> ${waiverName}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">for</p>
                                                                    <h4 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${filerName}
                                                                    </span></h4>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		has been received.<br>
                                                                        On behalf of the entire team here at BBR, 
                                                                        <br>"Welcome and have fun out there!"                                                                        
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0; font-size: 16px;">If you need a signed copy of this waiver for your records you may download it <a style="color: #ffffff;" href="${url}" target="_blank">here</a>. Or use your submission ID ${submissionID}</p>
																	<p style="text-align: center;margin: 0;">Thank you!</p>
																	<p style="text-align: center;margin: 0;">Best Regards.</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function tripStatusUpdateEmailBody (userName, bookingTitle, status) {
  return `
  <!DOCTYPE html>

<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">

<head>
	<title></title>
	<meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
	<meta content="width=device-width, initial-scale=1.0" name="viewport" />
	<!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
	<!--[if !mso]><!-->
	<link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
		rel="stylesheet" type="text/css" />
	<!--<![endif]-->
	<style>
		* {
			box-sizing: border-box;
		}

		body {
			margin: 0;
			padding: 0;
		}

		a[x-apple-data-detectors] {
			color: inherit !important;
			text-decoration: inherit !important;
		}

		#MessageViewBody a {
			color: inherit;
			text-decoration: none;
		}

		p {
			line-height: inherit
		}

		.desktop_hide,
		.desktop_hide table {
			mso-hide: all;
			display: none;
			max-height: 0px;
			overflow: hidden;
		}

		.image_block img+div {
			display: none;
		}

		@media (max-width:700px) {
			.desktop_hide table.icons-inner {
				display: inline-block !important;
			}

			.icons-inner {
				text-align: center;
			}

			.icons-inner td {
				margin: 0 auto;
			}

			.mobile_hide {
				display: none;
			}

			.row-content {
				width: 100% !important;
			}

			.stack .column {
				width: 100%;
				display: block;
			}

			.mobile_hide {
				min-height: 0;
				max-height: 0;
				max-width: 0;
				overflow: hidden;
				font-size: 0px;
			}

			.desktop_hide,
			.desktop_hide table {
				display: table !important;
				max-height: none !important;
			}

			.row-1 .column-1 .block-3.paragraph_block td.pad>div {
				font-size: 16px !important;
			}

			.row-1 .column-1 .block-2.heading_block h1 {
				font-size: 24px !important;
			}

			.row-1 .column-1 {
				padding: 40px 20px 30px !important;
			}
		}
	</style>
</head>

<body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
	<table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
		style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
		<tbody>
			<tr>
				<td>
					<table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
						role="presentation"
						style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
						width="100%">
						<tbody>
							<tr>
								<td>
									<table align="center" border="0" cellpadding="0" cellspacing="0"
										class="row-content stack" role="presentation"
										style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
										width="680">
										<tbody>
											<tr>
												<td class="column column-1"
													style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
													width="100%">
													<table border="0" cellpadding="0" cellspacing="0"
														class="icons_block block-1" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
														<tr>
															<td class="pad"
																style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
																<table align="center" cellpadding="0" cellspacing="0"
																	class="alignment" role="presentation"
																	style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
																	<tr>
																		<td
																			style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
																			<img align="center" class="icon"
																				height="128"
																				src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
																				style="display: block; height: auto; margin: 0 auto; border: 0;"
																				width="256" /></td>
																	</tr>
																</table>
															</td>
														</tr>
													</table>
													<table border="0" cellpadding="10" cellspacing="0"
														class="heading_block block-2" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
														width="100%">
													
													</table>
													<table border="0" cellpadding="0" cellspacing="0"
														class="paragraph_block block-3" role="presentation"
														style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
														width="100%">
														<tr>
															<td class="pad"
																style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
																<div
																	style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        Hi ${userName}
                                                                    </span></h1>
                                                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">trip of your booking</p>
																	<h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                        ${bookingTitle}
                                                                    </span></h1>
																	<p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
																		is ${status}.<br>
                                                                        On behalf of the entire team here at BBR,                                                                   
                                                                    </p>
                                                                    <p style="text-align: center;margin: 0;">Thank you!</p>
																</div>
															</td>
														</tr>
													</table>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
							</tr>
						</tbody>
					</table>
				</td>
			</tr>
		</tbody>
	</table><!-- End -->
</body>

</html>
  `;
}

export async function tripCompletedEmailBody (userName, bookingTitle, listingId, bookingId) {
  return `
  <!DOCTYPE html>

  <html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:v="urn:schemas-microsoft-com:vml">
  
  <head>
    <title></title>
    <meta content="text/html; charset=utf-8" http-equiv="Content-Type" />
    <meta content="width=device-width, initial-scale=1.0" name="viewport" />
    <!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]-->
    <!--[if !mso]><!-->
    <link href="https://fonts.googleapis.com/css2?family=Oswald:wght@100;200;300;400;500;600;700;800;900"
      rel="stylesheet" type="text/css" />
    <!--<![endif]-->
    <style>
      * {
        box-sizing: border-box;
      }
  
      body {
        margin: 0;
        padding: 0;
      }
  
      a[x-apple-data-detectors] {
        color: inherit !important;
        text-decoration: inherit !important;
      }
  
      #MessageViewBody a {
        color: inherit;
        text-decoration: none;
      }
  
      p {
        line-height: inherit
      }
  
      .desktop_hide,
      .desktop_hide table {
        mso-hide: all;
        display: none;
        max-height: 0px;
        overflow: hidden;
      }
  
      .image_block img+div {
        display: none;
      }
  
      @media (max-width:700px) {
        .desktop_hide table.icons-inner {
          display: inline-block !important;
        }
  
        .icons-inner {
          text-align: center;
        }
  
        .icons-inner td {
          margin: 0 auto;
        }
  
        .mobile_hide {
          display: none;
        }
  
        .row-content {
          width: 100% !important;
        }
  
        .stack .column {
          width: 100%;
          display: block;
        }
  
        .mobile_hide {
          min-height: 0;
          max-height: 0;
          max-width: 0;
          overflow: hidden;
          font-size: 0px;
        }
  
        .desktop_hide,
        .desktop_hide table {
          display: table !important;
          max-height: none !important;
        }
  
        .row-1 .column-1 .block-3.paragraph_block td.pad>div {
          font-size: 16px !important;
        }
  
        .row-1 .column-1 .block-2.heading_block h1 {
          font-size: 24px !important;
        }
  
        .row-1 .column-1 {
          padding: 40px 20px 30px !important;
        }
      }
    </style>
  </head>
  
  <body style="background-color: #171620; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
    <table border="0" cellpadding="0" cellspacing="0" class="nl-container" role="presentation"
      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #171620;" width="100%">
      <tbody>
        <tr>
          <td>
            <table align="center" border="0" cellpadding="0" cellspacing="0" class="row row-1"
              role="presentation"
              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-image: url('https://www.bbrbassboatrentals.com/images/bg_lines.png'); background-repeat: no-repeat; background-size: cover;"
              width="100%">
              <tbody>
                <tr>
                  <td>
                    <table align="center" border="0" cellpadding="0" cellspacing="0"
                      class="row-content stack" role="presentation"
                      style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;"
                      width="680">
                      <tbody>
                        <tr>
                          <td class="column column-1"
                            style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 60px; padding-top: 60px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;"
                            width="100%">
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="icons_block block-1" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 16px; font-weight: 400; padding-bottom: 45px; text-align: center;">
                                  <table align="center" cellpadding="0" cellspacing="0"
                                    class="alignment" role="presentation"
                                    style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tr>
                                      <td
                                        style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;">
                                        <img align="center" class="icon"
                                          height="128"
                                          src="https://www.bbrbassboatrentals.com/assets/backend/images/logo.webp"
                                          style="display: block; height: auto; margin: 0 auto; border: 0;"
                                          width="256" /></td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                            <table border="0" cellpadding="10" cellspacing="0"
                              class="heading_block block-2" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;"
                              width="100%">
                            
                            </table>
                            <table border="0" cellpadding="0" cellspacing="0"
                              class="paragraph_block block-3" role="presentation"
                              style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;"
                              width="100%">
                              <tr>
                                <td class="pad"
                                  style="padding-bottom:20px;padding-left:10px;padding-right:10px;padding-top:10px;">
                                  <div
                                    style="text-align: center; color:#acabaf;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:24px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:left;mso-line-height-alt:36px;">
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          Hi ${userName}
                                                                      </span></h1>
                                                                      <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;">your trip of booking</p>
                                    <h1 style="text-align: center; margin: 0; margin-bottom: 16px;  color: #ffffff; direction: ltr; font-family: 'Oswald', Arial, 'Helvetica Neue', Helvetica, sans-serif; font-size: 48px; font-weight: 700; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 57.599999999999994px;"><span class="tinyMce-placeholder">
                                                                          ${bookingTitle}
                                                                      </span></h1>
                                    <p style="text-align: center; margin: 0; margin-bottom: 16px; margin-top: 16px;  color: #ffffff;">
                                      is now completed.<br>
                                      <br>
                                      We are delighted that you completed your rental with BBR, we hope you had a great experience. Please leave a review for the boat owners by clicking this <a href="https://bbrbassboatrentals.com/listing/${listingId}">link</a>. 
                                      <br><br>Tips are also a great way to show your appreciation, if you'd like to leave a tip for outstanding service, please follow this <a href="https://bbrbassboatrentals.com/tip/${bookingId}">link</a>
                                      <br>On behalf of the entire team here at BBR,                                                                        
                                                                      </p>
                                    <br>
                                                                      <p style="text-align: center;margin: 0;">Thank you!</p>
                                  </div>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </tbody>
    </table><!-- End -->
  </body>
  
  </html>
  `;
}

export async function getAllOwnerSelectionQuestions() {
  try {
    const database = getDatabase();
    
    const pathToOwnerSelectionQuestions = 'portalSettings/settingsID/defaultOwnerQuestionsSignup/selectionQuestions';
    // Get a reference to the selectionQuestions path
    const selectionQuestionsRef = ref(database, pathToOwnerSelectionQuestions);

    // Retrieve data once from the database
    const snapshot = await get(selectionQuestionsRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Convert the data to an array
      const selectionQuestionsData = snapshot.val();
      const selectionQuestionsArray = Object.values(selectionQuestionsData);
      return selectionQuestionsArray;
    } else {
      // Return an empty array if no data is found
      return [];
    }
  } catch (error) {
    // Handle any errors that may occur during the database operation
    console.error("Error retrieving selectionQuestions:", error);
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
}

export async function getAllOwnerTextQuestions() {
    try {
      const database = getDatabase();
      
      const pathToOwnerTextQuestions = 'portalSettings/settingsID/defaultOwnerQuestionsSignup/textQuestions';
      // Get a reference to the selectionQuestions path
      const selectionQuestionsRef = ref(database, pathToOwnerTextQuestions);
  
      // Retrieve data once from the database
      const snapshot = await get(selectionQuestionsRef);
  
      // Check if data exists
      if (snapshot.exists()) {
        // Convert the data to an array
        const selectionQuestionsData = snapshot.val();
        const selectionQuestionsArray = Object.values(selectionQuestionsData);
        return selectionQuestionsArray;
      } else {
        // Return an empty array if no data is found
        console.log('test')
        return [];
      }
    } catch (error) {
      // Handle any errors that may occur during the database operation
      console.error("Error retrieving selectionQuestions:", error);
      throw error; // Re-throw the error to handle it at a higher level if needed
    }
}

export async function getAllRenterSignupQuestions() {
  try {
    const database = getDatabase();
    
    const pathToRenterSelectionQuestions = 'portalSettings/settingsID/defaultNewRenterQuestionsSignup/questions';
    // Get a reference to the selectionQuestions path
    const selectionQuestionsRef = ref(database, pathToRenterSelectionQuestions);

    // Retrieve data once from the database
    const snapshot = await get(selectionQuestionsRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Convert the data to an array
      const selectionQuestionsData = snapshot.val();
      const selectionQuestionsArray = Object.values(selectionQuestionsData);
      return selectionQuestionsArray;
    } else {
      // Return an empty array if no data is found
      return [];
    }
  } catch (error) {
    // Handle any errors that may occur during the database operation
    console.error("Error retrieving selectionQuestions:", error);
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
}
export async function getAllVerificationQuestions() {
  try {
    const database = getDatabase();
    
    const pathToRenterSelectionQuestions = 'portalSettings/settingsID/defaultVerificationQuestions/questions';
    // Get a reference to the selectionQuestions path
    const selectionQuestionsRef = ref(database, pathToRenterSelectionQuestions);

    // Retrieve data once from the database
    const snapshot = await get(selectionQuestionsRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Convert the data to an array
      const selectionQuestionsData = snapshot.val();
      const selectionQuestionsArray = Object.values(selectionQuestionsData);
      return selectionQuestionsArray;
    } else {
      // Return an empty array if no data is found
      return [];
    }
  } catch (error) {
    // Handle any errors that may occur during the database operation
    console.error("Error retrieving selectionQuestions:", error);
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
}

export async function getBecomeProQuestions() {
  try {
    const database = getDatabase();
    
    const pathToBecomeProQuestions = 'portalSettings/settingsID/defaultProQuestions/questions';
    // Get a reference to the selectionQuestions path
    const selectionQuestionsRef = ref(database, pathToBecomeProQuestions);

    // Retrieve data once from the database
    const snapshot = await get(selectionQuestionsRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Convert the data to an array
      const selectionQuestionsData = snapshot.val();
      const selectionQuestionsArray = Object.values(selectionQuestionsData);
      return selectionQuestionsArray;
    } else {
      // Return an empty array if no data is found
      return [];
    }
  } catch (error) {
    // Handle any errors that may occur during the database operation
    console.error("Error retrieving Become Pro questions:", error);
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
}

export async function getAllRenterSelectionQuestions() {
  try {
    const database = getDatabase();
    
    const pathToRenterSelectionQuestions = 'portalSettings/settingsID/defaultRenterQuestionsSignup/selectionQuestions';
    // Get a reference to the selectionQuestions path
    const selectionQuestionsRef = ref(database, pathToRenterSelectionQuestions);

    // Retrieve data once from the database
    const snapshot = await get(selectionQuestionsRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Convert the data to an array
      const selectionQuestionsData = snapshot.val();
      const selectionQuestionsArray = Object.values(selectionQuestionsData);
      return selectionQuestionsArray;
    } else {
      // Return an empty array if no data is found
      return [];
    }
  } catch (error) {
    // Handle any errors that may occur during the database operation
    console.error("Error retrieving selectionQuestions:", error);
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
}

export async function getAllRenterTextQuestions() {
    try {
      const database = getDatabase();
      
      const pathToRenterTextQuestions = 'portalSettings/settingsID/defaultRenterQuestionsSignup/textQuestions';
      // Get a reference to the selectionQuestions path
      const selectionQuestionsRef = ref(database, pathToRenterTextQuestions);
  
      // Retrieve data once from the database
      const snapshot = await get(selectionQuestionsRef);
  
      // Check if data exists
      if (snapshot.exists()) {
        // Convert the data to an array
        const selectionQuestionsData = snapshot.val();
        const selectionQuestionsArray = Object.values(selectionQuestionsData);
        return selectionQuestionsArray;
      } else {
        // Return an empty array if no data is found
        console.log('test')
        return [];
      }
    } catch (error) {
      // Handle any errors that may occur during the database operation
      console.error("Error retrieving selectionQuestions:", error);
      throw error; // Re-throw the error to handle it at a higher level if needed
    }
}

export async function getUserRoleByEmail(email) {
  try {
    const database = getDatabase();
    const usersRef = ref(database, 'users');

    // Create a query to filter users by email using the index
    const userQuery = query(usersRef, orderByChild('email'), equalTo(email));

    // Fetch the results of the query
    const snapshot = await get(userQuery);

    if (snapshot.exists()) {
      const userData = snapshot.val();
      console.log('userData: ', userData);
      const userId = Object.keys(userData)[0]; // Assuming email is unique, get the first key

      if (userId) {
        const user = userData[userId];
        const userRole = user.role;
        console.log('User role:', userRole);
        return userRole;
      } else {
        console.log('No user found with the provided email');
        return null;
      }
    } else {
      console.log('No data found in users node');
      return null;
    }
  } catch (error) {
    console.error('Error retrieving user role:', error);
    throw error;
  }
}

export async function getUserIdByRefferralCode(referralCode) {
  try {
      const referralRef = ref(database, 'refferalCodes');
      
      const snapshot = await get(referralRef);
      
      if (snapshot.exists()) {
          const refferalData = snapshot.val();

          const userWithMatchingRefferalCode = Object.values(refferalData).find(user => user.code === referralCode);

          const userId = userWithMatchingRefferalCode ? userWithMatchingRefferalCode.userId : null;
          console.log('User Id:', userId);

          return userId;
      } else {
          console.log('No data found');
          return null;
      }
  } catch (error) {
      console.error('Error retrieving user Id:', error);
      throw error;
  }
}

export async function getReferralCodeByUserId(userId) {
  try {
      const referralRef = ref(database, 'refferalCodes');
      
      const snapshot = await get(referralRef);
      
      if (snapshot.exists()) {
          const referralData = snapshot.val();

          const userWithMatchingUserId = Object.values(referralData).find(user => user.userId === userId);

          const referralCode = userWithMatchingUserId ? userWithMatchingUserId.code : null;
          console.log('Referral Code:', referralCode);

          return referralCode;
      } else {
          console.log('No data found');
          return null;
      }
  } catch (error) {
      console.error('Error retrieving referral code:', error);
      throw error;
  }
}

export async function getCurrentUserDetails() {
  try {
    const auth = getAuth();
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const uid = user.uid;
          const usersRef = ref(database, `users/${uid}`);
          const snapshot = await get(usersRef);
          if (snapshot.exists()) {
            const userDetails = snapshot.val();
            
            // Include userId in the userDetails
            const userDetailsWithId = {
              userId: uid,
              ...userDetails
            };

            resolve(userDetailsWithId);
          } else {
            console.log('No data found for the current user');
            resolve(null);
          }
        } else {
          console.log('No user signed in');
          resolve(null);
        }
      });
    });
  } catch (error) {
    console.error('Error retrieving current user details:', error);
    throw error;
  }
}


export async function getUserByID(uid) {
  try {
      return new Promise(async (resolve, reject) => {
          // Assuming `user` is a valid variable, make sure to check if the user is signed in
          // and handle the case where `user` is not defined
              const database = getDatabase();
              const usersRef = ref(database, `users/${uid}`);
              
              try {
                  const snapshot = await get(usersRef);
                  if (snapshot.exists()) {
                      const userDetails = snapshot.val();
                      resolve(userDetails);
                  } else {
                      console.log('No data found for the current user');
                      resolve(null);
                  }
              } catch (error) {
                  console.error('Error retrieving user details:', error);
                  reject(error);
              }
      });
  } catch (error) {
      console.error('Error retrieving current user details:', error);
      throw error;
  }
}

export async function getPlatformPercentages() {
  try {
      return new Promise(async (resolve, reject) => {
              const database = getDatabase();
              const platformPercentagesRef = ref(database, `portalSettings/settingsID`);
              
              try {
                  const snapshot = await get(platformPercentagesRef);
                  if (snapshot.exists()) {
                      const percentagesDetails = snapshot.val();
                      resolve(percentagesDetails);
                  } else {
                      console.log('No data found');
                      resolve(null);
                  }
              } catch (error) {
                  console.error('Error retrieving:', error);
                  reject(error);
              }
      });
  } catch (error) {
      console.error('Error retrieving', error);
      throw error;
  }
}
export async function getOwnerMeta(id) {
  try {
      return new Promise(async (resolve, reject) => {
              const database = getDatabase();
              const ownerMetaRef = ref(database, `ownerMeta/${id}`);
              
              try {
                  const snapshot = await get(ownerMetaRef);
                  if (snapshot.exists()) {
                      const ownerMetaDetails = snapshot.val();
                      resolve(ownerMetaDetails);
                  } else {
                      console.log('No data found');
                      resolve(null);
                  }
              } catch (error) {
                  console.error('Error retrieving:', error);
                  reject(error);
              }
      });
  } catch (error) {
      console.error('Error retrieving', error);
      throw error;
  }
}



export async function getPlatformHourlyRate() {
  try {
      return new Promise(async (resolve, reject) => {
              const database = getDatabase();
              const hourlyRateRef = ref(database, `portalSettings/settingsID/hourlyRate`);
              
              try {
                  const snapshot = await get(hourlyRateRef);
                  if (snapshot.exists()) {
                      const hourlyRate = snapshot.val();
                      resolve(hourlyRate);
                  } else {
                      console.log('No data found');
                      resolve(null);
                  }
              } catch (error) {
                  console.error('Error retrieving:', error);
                  reject(error);
              }
      });
  } catch (error) {
      console.error('Error retrieving', error);
      throw error;
  }
}

export async function getBoatTypes() {
  try {
      return new Promise(async (resolve, reject) => {
              const database = getDatabase();
              const boatTypesRef = ref(database, `portalSettings/settingsID/boatTypes`);
              
              try {
                  const snapshot = await get(boatTypesRef);
                  if (snapshot.exists()) {
                      const boatTypes = snapshot.val();
                      resolve(boatTypes);
                  } else {
                      console.log('No data found');
                      resolve(null);
                  }
              } catch (error) {
                  console.error('Error retrieving:', error);
                  reject(error);
              }
      });
  } catch (error) {
      console.error('Error retrieving', error);
      throw error;
  }
}

export function getBookingByID(bookingID) {
  return new Promise((resolve, reject) => {
    try {
      const database = getDatabase();
      const bookingsRef = ref(database, `bookings/${bookingID}`);
      
      onValue(bookingsRef, (snapshot) => {
        if (snapshot.exists()) {
          const bookingDetails = snapshot.val();
          resolve(bookingDetails);
        } else {
          console.log('No data found for the current booking');
          resolve(null);
        }
      }, (error) => {
        console.error('Error retrieving booking details:', error);
        reject(error);
      });
    } catch (error) {
      console.error('Error retrieving booking details:', error);
      reject(error);
    }
  });
}


export async function getPaymentById(id) {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const bookingsRef = ref(database, `payments/${id}`);
      
      try {
        const snapshot = await get(bookingsRef);
        if (snapshot.exists()) {
          const bookingDetails = snapshot.val();
          resolve(bookingDetails);
        } else {
          console.log('No data found for the current booking');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving booking details:', error);
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving booking details:', error);
    throw error;
  }
}
export async function getPendingPaymentById(id) {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const bookingsRef = ref(database, `pendingPayments/${id}`);
      
      try {
        const snapshot = await get(bookingsRef);
        if (snapshot.exists()) {
          const bookingDetails = snapshot.val();
          resolve(bookingDetails);
        } else {
          console.log('No data found for the current booking');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving booking details:', error);
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving booking details:', error);
    throw error;
  }
}

export async function getDisputeDetailsById(id) {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const disputesRef = ref(database, `disputes/${id}`);
      
      try {
        const snapshot = await get(disputesRef);
        if (snapshot.exists()) {
          const disputeDetails = snapshot.val();
          resolve(disputeDetails);
        } else {
          console.log('No data found for the current dispute');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving dispute details:', error);
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving dispute details:', error);
    throw error;
  }
}

export async function getOfferDetailsByBookingId(bookingID) {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const bookingsRef = ref(database, `bookings/${bookingID}`);
      
      try {
        const snapshot = await get(bookingsRef);
        if (snapshot.exists()) {
          const bookingDetails = snapshot.val();
          // Get offer details from offers collection using offer ID
          const offerDetails = await getOfferDetailsByInquiryId(bookingDetails.inquiryId);
          resolve(offerDetails);
        } else {
          console.log('No data found for the current booking');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving booking details:', error);
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving booking details:', error);
    throw error;
  }
}

export async function getUserMetaByID(uid) {
  try {
    const database = getDatabase();
    const userMetaRef = ref(database, `userMeta/${uid}`);

    try {
      const snapshot = await get(userMetaRef);

      if (snapshot.exists()) {
        const userMeta = snapshot.val();
        return userMeta;
      } else {
        console.log('No userMeta found for the current user');
        return null;
      }
    } catch (error) {
      console.error('Error retrieving userMeta:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error retrieving userMeta:', error);
    throw error;
  }
}

export async function getOwnerMetaByID(uid) {
  try {
    const database = getDatabase();
    const userMetaRef = ref(database, `ownerMeta/${uid}`);

    try {
      const snapshot = await get(userMetaRef);

      if (snapshot.exists()) {
        const userMeta = snapshot.val();
        return userMeta;
      } else {
        console.log('No ownerMeta found for the current user');
        return null;
      }
    } catch (error) {
      console.error('Error retrieving ownerMeta:', error);
      throw error;
    }
  } catch (error) {
    console.error('Error retrieving ownerMeta:', error);
    throw error;
  }
}

export async function updateUserStatusById(userId, newStatus, rejectReason) {
  try {
    const usersRef = ref(database, `users/${userId}`);

    // Update the user status in the database
    await update(usersRef, { status: newStatus, rejectionReason: rejectReason });

    console.log(`User status updated successfully for user with ID ${userId}`);
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
}


export async function updateListingStatusById(listingId, newStatus) {
  try {
    const listingRef = ref(database, `listings/${listingId}`);

    // Update the user status in the database
    await update(listingRef, { boatStatus: newStatus });

    console.log(`tatus updated successfully`);
  } catch (error) {
    console.error('Error updating status:', error);
    throw error;
  }
}

export async function getAllBoatOwners() {
  try {
    const database = getDatabase();
    const usersRef = ref(database, 'users');
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const allUsersDetails = [];
      snapshot.forEach((userSnapshot) => {
        const userId = userSnapshot.key; // Get the userId (Firebase key)
        const userDetails = userSnapshot.val();
        if(userDetails.role === 'Boat Owner') {
          const userWithId = { userId, ...userDetails }; // Include userId in the user object
          allUsersDetails.push(userWithId);
        }
      });
      return allUsersDetails;
    } else {
      console.log('No users found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving all users details:', error);
    throw error;
  }
}

export async function getAllUsersDetails() {
  try {
    const database = getDatabase();
    const usersRef = ref(database, 'users');
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const allUsersDetails = [];
      snapshot.forEach((userSnapshot) => {
        const userId = userSnapshot.key; // Get the userId (Firebase key)
        const userDetails = userSnapshot.val();
        const userWithId = { userId, ...userDetails }; // Include userId in the user object

        allUsersDetails.push(userWithId);
      });
      return allUsersDetails;
    } else {
      console.log('No users found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving all users details:', error);
    throw error;
  }
}

export async function getAllPendingUsers() {
  try {
    const database = getDatabase();
    const usersRef = ref(database, 'users');
    const pendingUsersQuery = query(usersRef, orderByChild('status'), equalTo('Pending'));
    const snapshot = await get(pendingUsersQuery);

    if (snapshot.exists()) {
      const pendingUsersDetails = [];
      snapshot.forEach((userSnapshot) => {
        const userId = userSnapshot.key;
        const userDetails = userSnapshot.val();
        const userWithId = { id: userId, ...userDetails };
        pendingUsersDetails.push(userWithId);
      });
      return pendingUsersDetails;
    } else {
      console.log('No pending users found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving pending users details:', error);
    throw error;
  }
}

export async function getAllAttributes() {
  try {
    const database = getDatabase();
    const attRef = ref(database, 'portalSettings/settingsID/defaultAttributesListings');
    const snapshot = await get(attRef);

    if (snapshot.exists()) {
      const allAttDetails = [];
      snapshot.forEach((userSnapshot) => {
        const attDetails = userSnapshot.val();
        const attWithId = {...attDetails };
        allAttDetails.push(attWithId);
      });
      return allAttDetails;
    } else {
      console.log('No Attributes found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving all Attributes:', error);
    throw error;
  }
}


export async function getReviewsByListingId(listingId) {
  try {
    const database = getDatabase();
    const revRef = ref(database, `reviews/${listingId}`);
    const snapshot = await get(revRef);

    if (snapshot.exists()) {
      const allRevDetails = [];
      snapshot.forEach((userSnapshot) => {
        const revDetails = userSnapshot.val();
        const revWithId = {...revDetails };
        allRevDetails.push(revWithId);
      });
      return allRevDetails;
    } else {
      console.log('No Review found');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving all Review:', error);
    throw error;
  }
}


export async function getAllWaiversData() {
  try {
    const database = getDatabase();
    const waiversRef = ref(database, 'waivers');
    const snapshot = await get(waiversRef);

    if (snapshot.exists()) {
      const allWaiversData = [];
      snapshot.forEach((waiverSnapshot) => {
        const waiverId = waiverSnapshot.key; // Get the waiverId (Firebase key)
        const waiverData = waiverSnapshot.val();
        const waiverWithId = { waiverId, ...waiverData }; // Include waiverId in the waiver object
        allWaiversData.push(waiverWithId);
      });
      return allWaiversData;
    } else {
      console.log('No waivers found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving all waivers data:', error);
    throw error;
  }
}

export async function getWaiverById(waiverId) {
  try {
    const database = getDatabase();
    const waiverRef = ref(database, `waivers/${waiverId}`);
    const snapshot = await get(waiverRef);

    if (snapshot.exists()) {
      const waiverData = snapshot.val();
      return { waiverId, ...waiverData };
    } else {
      console.log(`Waiver with ID ${waiverId} not found in the database`);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving waiver data:', error);
    throw error;
  }
}

export async function getWaiverDataByName(waiverName) {
  try {
    const allWaiversData = await getAllWaiversData();
    const waiver = allWaiversData.find((waiver) => waiver.waiverName === waiverName);

    if (waiver) {
      return waiver.questions;
    } else {
      console.log(`Waiver with name '${waiverName}' not found`);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving waiver data by name:', error);
    throw error;
  }
}



export async function getWaiverDescriptionByName(waiverName) {
  try {
    const allWaiversData = await getAllWaiversData();
    const waiver = allWaiversData.find((waiver) => waiver.waiverName === waiverName);

    if (waiver) {
      return waiver.waiverDescription;
    } else {
      console.log(`Waiver with name '${waiverName}' not found`);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving waiver data by name:', error);
    throw error;
  }
}


export async function getAllUsersMeta() {
  try {
    const database = getDatabase();
    const usersRef = ref(database, 'userMeta');
    const snapshot = await get(usersRef);

    if (snapshot.exists()) {
      const allUsersDetails = [];
      snapshot.forEach((userSnapshot) => {
        const userDetails = userSnapshot.val();
        allUsersDetails.push(userDetails);
      });
      return allUsersDetails;
    } else {
      console.log('No users found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving all users details:', error);
    throw error;
  }
}

export async function getListingCharterOptions() {
  try {
    const database = getDatabase();
    
    const pathToCharterOptions = 'portalSettings/settingsID/defaultAttributesListings/charterOptions';
    // Get a reference to the selectionQuestions path
    const charterOptionsRef = ref(database, pathToCharterOptions);

    // Retrieve data once from the database
    const snapshot = await get(charterOptionsRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Convert the data to an array
      const charterOptionsData = snapshot.val();
      const charterOptionsArray = Object.values(charterOptionsData);
      return charterOptionsArray;
    } else {
      // Return an empty array if no data is found
      return [];
    }
  } catch (error) {
    // Handle any errors that may occur during the database operation
    console.error("Error retrieving charterOptionsArray:", error);
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
}

export async function getListingFeaturesOptions() {
  try {
    const database = getDatabase();
    
    const pathToListingFeatures = 'portalSettings/settingsID/defaultAttributesListings/listingFeatures';
    // Get a reference to the selectionQuestions path
    const listingFeaturesRef = ref(database, pathToListingFeatures);

    // Retrieve data once from the database
    const snapshot = await get(listingFeaturesRef);

    // Check if data exists
    if (snapshot.exists()) {
      // Convert the data to an array
      const listingFeaturesData = snapshot.val();
      const listingFeaturesArray = Object.values(listingFeaturesData);
      return listingFeaturesArray;
    } else {
      // Return an empty array if no data is found
      return [];
    }
  } catch (error) {
    // Handle any errors that may occur during the database operation
    console.error("Error retrieving listingFeaturesArray:", error);
    throw error; // Re-throw the error to handle it at a higher level if needed
  }
}

export async function getAllListings() {
  try {
    const database = getDatabase();
    const listingsRef = ref(database, 'listings');
    const snapshot = await get(listingsRef);

    if (snapshot.exists()) {
      const allListingsDetails = [];
      snapshot.forEach((listingSnapshot) => {
        const listingId = listingSnapshot.key;
        const userDetails = listingSnapshot.val();
        const listingWithId = { id: listingId, ...userDetails };
        allListingsDetails.push(listingWithId);
      });
      return allListingsDetails;
    } else {
      console.log('No listings found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving all listings details:', error);
    throw error;
  }
}

export async function getActiveListings() {
  try {
    const database = getDatabase();
    const listingsRef = ref(database, 'listings');
    const activeListingsQuery = query(listingsRef, orderByChild('boatStatus'), equalTo('Active'));
    const snapshot = await get(activeListingsQuery);

    if (snapshot.exists()) {
      const allListingsDetails = [];
      snapshot.forEach((listingSnapshot) => {
        const listingId = listingSnapshot.key;
        const userDetails = listingSnapshot.val();
        const listingWithId = { id: listingId, ...userDetails };
        allListingsDetails.push(listingWithId);
      });
      return allListingsDetails;
    } else {
      console.log('No active listings found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving active listings details:', error);
    throw error;
  }
}


export async function getAllPendingListings() {
  try {
    const database = getDatabase();
    const listingsRef = ref(database, 'listings');
    const activeListingsQuery = query(listingsRef, orderByChild('boatStatus'), equalTo('Pending'));
    const snapshot = await get(activeListingsQuery);

    if (snapshot.exists()) {
      const allListingsDetails = [];
      snapshot.forEach((listingSnapshot) => {
        const listingId = listingSnapshot.key;
        const userDetails = listingSnapshot.val();
        const listingWithId = { id: listingId, ...userDetails };
        allListingsDetails.push(listingWithId);
      });
      return allListingsDetails;
    } else {
      console.log('No active listings found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving active listings details:', error);
    throw error;
  }
}

export async function getAllDisputes() {
  try {
    const database = getDatabase();
    const disputesRef = ref(database, 'disputes');
    const snapshot = await get(disputesRef);

    if (snapshot.exists()) {
      const allDisputesDetails = [];
      snapshot.forEach((disputeSnapshot) => {
        const disputeId = disputeSnapshot.key;
        const disputeDetails = disputeSnapshot.val();
        const disputeWithId = { id: disputeId, ...disputeDetails };
        allDisputesDetails.push(disputeWithId);
      });
      return allDisputesDetails;
    } else {
      console.log('No disputes found in the database');
      return [];
    }
  } catch (error) {
    console.error('Error retrieving all disputes details:', error);
    throw error;
  }
}


export async function getListingByBookingId(id) {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      
      const bookingDetails = await getBookingByID(id);
      console.log('bookingDetails: ',bookingDetails);
      
      const inquiryId = bookingDetails.inquiryId;
      console.log('inquiryId: ',bookingDetails.inquiryId);
      
      const inquiryDetails = await getInquiryDataById(inquiryId);
      const listingId = inquiryDetails.listingId;
      const listingsRef = ref(database, `listings/${listingId}`);
      console.log('listingId: ',listingId);

      try {
        const snapshot = await get(listingsRef);
        if (snapshot.exists()) {
          const listing = snapshot.val();

          if (listing) {
            resolve(listing);
          } else {
            console.log('No data found for the listing');
            resolve(null);
          }
        } else {
          console.log('No data found for the listings');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving listing details:', error);
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving listing details:', error);
    throw error;
  }
}

export async function getListingByID(id) {
  try {
    const database = getDatabase();
    const listingRef = ref(database, `listings/${id}`);

    // Fetch the specific listing by its ID
    const snapshot = await get(listingRef);

    if (snapshot.exists()) {
      // Directly return the data if it exists
      const listing = snapshot.val();
      return { id, ...listing };
    } else {
      console.log('No data found for the listing with ID:', id);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving listing details:', error);
    throw error;
  }
}

export async function getListingLockDatesByID(id) {
	try {
	  return new Promise(async (resolve, reject) => {
		const database = getDatabase();
		const listingsRef = ref(database, `listings/${id}/lockDates`);
  
		try {
		  const snapshot = await get(listingsRef);
		  if (snapshot.exists()) {
			const listingsObject = snapshot.val();
  
			if (listingsObject) {
			  resolve(listingsObject);
			} else {
			  console.log('No Lock Dates');
			  resolve(null);
			}
		  } else {
			console.log('No Lock Dates');
			resolve(null);
		  }
		} catch (error) {
		  console.error('Error retrieving No Lock Dates details:', error);
		  reject(error);
		}
	  });
	} catch (error) {
	  console.error('Error retrieving No Lock Dates details:', error);
	  throw error;
	}
  }

export async function getListingByUserID(userId) {
  try {
    const database = getDatabase();
    const listingsRef = ref(database, 'listings');

    // Create a query to filter listings by ownerId using the index
    const listingsQuery = query(listingsRef, orderByChild('ownerId'), equalTo(userId));

    // Fetch the results of the query
    const snapshot = await get(listingsQuery);

    if (snapshot.exists()) {
      const listingsObject = snapshot.val();

      // Convert the object of objects to an array of objects with 'id' property
      const listingsArray = Object.keys(listingsObject).map((key) => ({
        id: key,
        ...listingsObject[key],
      }));

      return listingsArray;
    } else {
      console.log('No listings found for the user.');
      return null;
    }
  } catch (error) {
    console.error('Error retrieving listings:', error);
    throw error;
  }
}


export async function getInquiryDataById(id) {
  try {
    const database = getDatabase();
    const inquiryRef = ref(database, `inquiries/${id}`);

    // Fetch the inquiry data
    const snapshot = await get(inquiryRef);

    if (snapshot.exists()) {
      const inquiryDetail = snapshot.val();
      // Include the ID in the returned object
      return { id, ...inquiryDetail };
    } else {
      console.log('No data found for the inquiry ID:', id);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving inquiry data:', error);
    throw error;
  }
}

export async function getOfferDetailsByInquiryId(id) {
  try {
    const offerRef = ref(database, `inquiries/${id}`);

    const offerSnapshot = await get(offerRef);
        return offerSnapshot.val().offerData;
    } 
    catch (error) {
    console.error('Error retrieving inquiries:', error);
    throw error;
  }
}

export async function getBookingsByUserId(userId) {
  try {
    const database = getDatabase();

    // Create queries for both owner and renter fields
    const ownerQuery = query(ref(database, 'bookings'), orderByChild('owner'), equalTo(userId));
    const renterQuery = query(ref(database, 'bookings'), orderByChild('renter'), equalTo(userId));

    // Fetch results from both queries concurrently
    const [ownerSnapshot, renterSnapshot] = await Promise.all([
      get(ownerQuery),
      get(renterQuery)
    ]);

    // Initialize arrays to store results
    const ownerBookings = ownerSnapshot.exists() ? ownerSnapshot.val() : {};
    const renterBookings = renterSnapshot.exists() ? renterSnapshot.val() : {};

    // Combine and deduplicate results
    const allBookings = { ...ownerBookings, ...renterBookings };
    const bookingsArray = Object.keys(allBookings).map((key) => ({
      id: key,
      ...allBookings[key]
    }));

    // Return filtered bookings
    if (bookingsArray.length > 0) {
      return bookingsArray;
    } else {
      console.log('No data found for the bookings with userId:', userId);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving booking details:', error);
    throw error;
  }
}

export async function getAllBookings() {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const bookingsRef = ref(database, 'bookings'); // Updated variable name to 'bookingsRef'

      try {
        const snapshot = await get(bookingsRef); // Updated variable name to 'bookingsRef'
        if (snapshot.exists()) {
          const bookingsObject = snapshot.val(); // Updated variable name to 'bookingsObject'

          // Convert the object of objects to an array of objects with 'id' property
          const bookingsArray = Object.keys(bookingsObject).map((key) => ({
            id: key,
            ...bookingsObject[key],
          }));

            // Assuming you want to return an array of matching items
            resolve(bookingsArray);
        } else {
          console.log('No data found for the bookings');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving booking details:', error); // Updated error message
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving booking details:', error); // Updated error message
    throw error;
  }
}

export async function getWithdrawalRequests() {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const withdrawalsRef = ref(database, 'withdrawals'); // Updated variable name to 'withdrawalsRef'

      try {
        const snapshot = await get(withdrawalsRef); // Updated variable name to 'withdrawalsRef'
        if (snapshot.exists()) {
          const withdrawalsObject = snapshot.val(); // Updated variable name to 'withdrawalsObject'

          // Convert the object of objects to an array of objects with 'id' property
          const withdrawalsArray = Object.keys(withdrawalsObject).map((key) => ({
            id: key,
            ...withdrawalsObject[key],
          }));

          if (withdrawalsArray.length > 0) {
            // Assuming you want to return an array of matching items
            resolve(withdrawalsArray);
          } else {
            console.log('No data found for the withdrawals:');
            resolve(null);
          }
        } else {
          console.log('No data found for the withdrawals');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving withdrawal details:', error); // Updated error message
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving withdrawal details:', error); // Updated error message
    throw error;
  }
}
export async function getCurrentUserWithdrawalRequests(userId) {
  try {
    const database = getDatabase();
    const withdrawalsRef = ref(database, 'withdrawals');

    // Create a query to filter withdrawals by userId using the index
    const withdrawalsQuery = query(withdrawalsRef, orderByChild('userId'), equalTo(userId));

    // Fetch the results of the query
    const snapshot = await get(withdrawalsQuery);

    if (snapshot.exists()) {
      const withdrawalsObject = snapshot.val();

      // Convert the object of objects to an array of objects with 'id' property
      const withdrawalsArray = Object.keys(withdrawalsObject).map((key) => ({
        id: key,
        ...withdrawalsObject[key],
      }));

      if (withdrawalsArray.length > 0) {
        return withdrawalsArray;
      } else {
        console.log('No data found for the userId:', userId);
        return null;
      }
    } else {
      console.log('No data found for the withdrawals');
      return null;
    }
  } catch (error) {
    console.error('Error retrieving withdrawal details:', error);
    throw error;
  }
}


export async function getAllPayments() {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const bookingsRef = ref(database, 'payments'); // Updated variable name to 'bookingsRef'

      try {
        const snapshot = await get(bookingsRef); // Updated variable name to 'bookingsRef'
        if (snapshot.exists()) {
          const bookingsObject = snapshot.val(); // Updated variable name to 'bookingsObject'

          // Convert the object of objects to an array of objects with 'id' property
          const bookingsArray = Object.keys(bookingsObject).map((key) => ({
            id: key,
            ...bookingsObject[key],
          }));

          if (bookingsArray.length > 0) {
            // Assuming you want to return an array of matching items
            resolve(bookingsArray);
          } else {
            console.log('No data found for the bookings:');
            resolve(null);
          }
        } else {
          console.log('No data found for the bookings');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving booking details:', error); // Updated error message
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving booking details:', error); // Updated error message
    throw error;
  }
}

export async function getPaymentsByUserId(userId) {
  try {
    const database = getDatabase();
    const paymentsRef = ref(database, 'payments');

    // Create queries for each relevant field
    const ownerQuery = query(paymentsRef, orderByChild('owner'), equalTo(userId));
    const referrerQuery = query(paymentsRef, orderByChild('referrer'), equalTo(userId));
    const userQuery = query(paymentsRef, orderByChild('userId'), equalTo(userId));

    // Fetch results from all queries concurrently
    const [ownerSnapshot, referrerSnapshot, userSnapshot] = await Promise.all([
      get(ownerQuery),
      get(referrerQuery),
      get(userQuery)
    ]);

    // Initialize arrays to store results
    const ownerPayments = ownerSnapshot.exists() ? ownerSnapshot.val() : {};
    const referrerPayments = referrerSnapshot.exists() ? referrerSnapshot.val() : {};
    const userPayments = userSnapshot.exists() ? userSnapshot.val() : {};

    // Combine results into one object, ensuring no duplicates
    const allPayments = { ...ownerPayments, ...referrerPayments, ...userPayments };

    // Convert the combined object to an array of objects with 'id' property
    const paymentsArray = Object.keys(allPayments).map((key) => ({
      id: key,
      ...allPayments[key]
    }));

    if (paymentsArray.length > 0) {
      return paymentsArray;
    } else {
      console.log('No data found for the userId:', userId);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving payment details:', error);
    throw error;
  }
}

export async function checkCoupon(coupon) {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const couponCodesRef = ref(database, 'portalSettings/settingsID/couponCodes');

      try {
        const snapshot = await get(couponCodesRef); // Updated variable name to 'couponCodesRef'
        if (snapshot.exists()) {
          const couponCodesObject = snapshot.val(); // Updated variable name to 'couponCodesObject'

          // Convert the object of objects to an array of objects with 'id' property
          const couponCodesArray = Object.keys(couponCodesObject).map((key) => ({
            id: key,
            ...couponCodesObject[key],
          }));

          // Filter the coupon codes to only include those with coupon equal to the provided coupon
          const filteredCouponCodes = couponCodesArray.filter((code) => code.coupon === coupon);

          if (filteredCouponCodes.length > 0) {
            // Assuming you want to return an array of matching items
            resolve(filteredCouponCodes);
          } else {
            console.log('No data found for the coupon code:', coupon);
            resolve(null);
          }
        } else {
          console.log('No data found for the coupon codes');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving coupon code details:', error); // Updated error message
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving coupon code details:', error); // Updated error message
    throw error;
  }
}


export async function getBookingsByListingId(id) {
  try {
    const database = getDatabase();
    const bookingsRef = ref(database, 'bookings');
    
    // Create a query that filters bookings by listingId using the index
    const bookingsQuery = query(bookingsRef, orderByChild('listingId'), equalTo(id));

    // Fetch the results of the query
    const snapshot = await get(bookingsQuery);

    if (snapshot.exists()) {
      const bookingsObject = snapshot.val();

      // Convert the object of objects to an array of objects with 'id' property
      const bookingsArray = Object.keys(bookingsObject).map((key) => ({
        id: key,
        ...bookingsObject[key],
      }));

      // Return the filtered bookings
      return bookingsArray;
    } else {
      console.log('No data found for the bookings with listingId:', id);
      return null;
    }
  } catch (error) {
    console.error('Error retrieving booking details:', error);
    throw error;
  }
}

export async function getEventsByListingId(id) {
  try {
    const bookings = await getBookingsByListingId(id);
    console.log("individualBookings");
    console.log(bookings);

    const lockDates = await getListingLockDatesByID(id);

    if(bookings != null || lockDates != null) {

    const events = Array.isArray(bookings) && bookings.length > 0
      ? await Promise.all(bookings.map(async (booking) => {
        const newEvent = {
          id: booking.id,
          title: 'Booked', // Assuming there is a title property in your booking object
          color: 'orange'
        };

        const singleOffer = await getOfferDetailsByInquiryId(booking.inquiryId);

        const startDateString = `${singleOffer.offeredDate} ${singleOffer.offeredTime}`;
        const startDate = new Date(startDateString);

        // Calculate endDate by adding offeredHours to startDate
        const endDate = new Date(startDate);

        let endHours = 0;
        if (singleOffer && singleOffer.offeredHours > 0) {
          endHours = singleOffer.offeredHours;
        } else if (singleOffer && singleOffer.overnight > 0) {
          endHours = singleOffer.overnight * 24;
        }
        endDate.setHours(endDate.getHours() + endHours); // Assuming events last for 12 hours

        newEvent.start = startDate;
        newEvent.end = endDate;

        return newEvent;
      })) 
      : [];
	  const disabledDatesFromLockDates = lockDates
		? Object.values(lockDates).map(lockDate => ({ 
			title: 'Locked', // Assuming there is a title property in your lockDate object
			color: 'orange',
			start: new Date(lockDate.start_date),
			end: new Date(lockDate.end_date),
		}))
		: [];
		return [...events, ...disabledDatesFromLockDates];
	}

  } catch (error) {
    console.log('Error retrieving events:', error.message);
    throw error;
  }
}


export async function getEventsByID(id) {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const listingsRef = ref(database, 'events');

      try {
        const snapshot = await get(listingsRef);
        if (snapshot.exists()) {
          const listingsObject = snapshot.val();

          // Convert the object of objects to an array of objects with 'id' property
          const listingsArray = Object.keys(listingsObject).map((key) => ({
            id: key,
            ...listingsObject[key],
          }));

          const filteredListings = listingsArray.filter((item) => item.listingId === id);

          if (filteredListings.length > 0) {
            // Assuming you want to return an array of matching items
            resolve(filteredListings);
          } else {
            console.log('No data found for the listings with :', id);
            resolve(null);
          }
        } else {
          console.log('No data found for the listings');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving listing details:', error);
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving listing details:', error);
    throw error;
  }
}

export async function getEventsByUserId(userId) {
  try {
    const bookings = await getBookingsByUserId(userId);
    
    const events = Array.isArray(bookings) && bookings.length > 0
      ? bookings.map(async (booking) => {
        const newEvent = {
          id: booking.id,
          title: booking.listingTitle, // Assuming there is a title property in your booking object
          color:
          booking.status === ('pending' || "Pending") ? 'red' :
          booking.status === 'Paid' ? '#2196f3' :
          booking.status === 'Waiver Pending' ? '#651fff' :
          booking.status === 'tripStarted' ? 'orange' :
          booking.status === 'Trip Completed' ? '#39570f' :
          booking.status === 'Trip Concluded' ? 'green' :
          'black',
      };

        const singleOffer = await getOfferDetailsByInquiryId(booking.inquiryId);

        const startDateString = `${singleOffer.offeredDate} ${singleOffer.offeredTime}`;
        const startDate = new Date(startDateString);
        
        // Calculate endDate by adding offeredHours to startDate
        const endDate = new Date(startDate);
        let endHours = 0;
        if(singleOffer && singleOffer.offeredHours > 0) {
           endHours = singleOffer.offeredHours;
        }
        else if(singleOffer && singleOffer.overnight > 0 ) {
          endHours = singleOffer.overnight * 24;
        }
        endDate.setHours(endDate.getHours() + endHours); // Assuming events last for 12 hours

        newEvent.start = startDate;
        newEvent.end = endDate;

        return newEvent;
      })
      : [];

    // Await all asynchronous operations inside the map function
    return Promise.all(events);
  } catch (error) {
    console.log('Error retrieving events:', error.message);
    throw error;
  }
}

export async function getAllEvents(userId) {
  try {
    const bookings = await getAllBookings();
    console.log("individualBookings");
    console.log(bookings);
    
    const events = Array.isArray(bookings) && bookings.length > 0
      ? bookings.map(async (booking) => {
        const newEvent = {
          id: booking.id,
          title: booking.listingTitle, // Assuming there is a title property in your booking object
          color:
          booking.status === ('pending' || "Pending") ? 'red' :
          booking.status === 'Paid' ? '#2196f3' :
          booking.status === 'Waiver Pending' ? '#651fff' :
          booking.status === 'tripStarted' ? 'orange' :
          booking.status === 'Trip Completed' ? '#39570f' :
          booking.status === 'Trip Concluded' ? 'green' :
          'black',
      };

        const singleOffer = await getOfferDetailsByInquiryId(booking.inquiryId);

        const startDateString = `${singleOffer.offeredDate} ${singleOffer.offeredTime}`;
        const startDate = new Date(startDateString);
        
        // Calculate endDate by adding offeredHours to startDate
        const endDate = new Date(startDate);
        let endHours = 0;
        if(singleOffer && singleOffer.offeredHours > 0) {
           endHours = singleOffer.offeredHours;
        }
        else if(singleOffer && singleOffer.overnight > 0 ) {
          endHours = singleOffer.overnight * 24;
        }
        endDate.setHours(endDate.getHours() + endHours); // Assuming events last for 12 hours

        newEvent.start = startDate;
        newEvent.end = endDate;

        return newEvent;
      })
      : [];

    // Await all asynchronous operations inside the map function
    return Promise.all(events);
  } catch (error) {
    console.log('Error retrieving events:', error.message);
    throw error;
  }
}

export async function getBoatOwnerPayments(id) {
  try {
    const userDetails = await getUserByID(id);
    console.log('userDetails');
    console.log(userDetails);

    if(userDetails.role == 'Boat Owner') {
      const database = getDatabase();
      const ownerMetaPayments = ref(database, `ownerMeta/${id}`);
      const snapshot = await get(ownerMetaPayments);
      
      if(snapshot.exists()) {
        const paymentDetails = snapshot.val();

        // Convert paymentDetails object to an array of promises
        const promises = Object.values(paymentDetails).map((value) => Promise.resolve(value));

        // Wait for all promises to resolve
        const result = await Promise.all(promises);

        return result;
      }
  }
  } catch (error) {
    console.log('Error retrieving ownermeta payments: ', error.message); 
  }
}

export async function getEventDetailsByEventID(id) {
  try {
    return new Promise(async (resolve, reject) => {
      const database = getDatabase();
      const listingsRef = ref(database, 'events');

      try {
        const snapshot = await get(listingsRef);
        if (snapshot.exists()) {
          const listingsObject = snapshot.val();

          // Convert the object of objects to an array of objects with 'id' property
          const listingsArray = Object.keys(listingsObject).map((key) => ({
            id: key,
            ...listingsObject[key],
          }));

          const filteredListings = listingsArray.filter((item) => item.id === id);

          if (filteredListings.length > 0) {
            // Assuming you want to return an array of matching items
            resolve(filteredListings);
          } else {
            console.log('No data found for the listings with :', id);
            resolve(null);
          }
        } else {
          console.log('No data found for the listings');
          resolve(null);
        }
      } catch (error) {
        console.error('Error retrieving listing details:', error);
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error retrieving listing details:', error);
    throw error;
  }
}


export const updateDisputeStatus = async (disputeId, status) => {
  try {
    const database = getDatabase();
    // Assuming you are using Firebase Realtime Database
    await update(ref(database, `disputes/${disputeId}`), { status });
    console.log(`dispute ${disputeId} status updated to ${status}`);
  } catch (error) {
    console.error('Error updating dispute status:', error);
  }
};

export const updateBookingStatus = async (bookingId, status) => {
  try {
    const database = getDatabase();
    // Assuming you are using Firebase Realtime Database
    await update(ref(database, `bookings/${bookingId}`), { status });
    console.log(`Booking ${bookingId} status updated to ${status}`);
  } catch (error) {
    console.error('Error updating booking status:', error);
  }
};

export const updateProStaffStatus = async (userId, proStaffStatus
  ) => {
  try {
    const database = getDatabase();
    // Assuming you are using Firebase Realtime Database
    await update(ref(database, `ownerMeta/${userId}`), { proStaffStatus
    });
    console.log(`${userId} status updated to ${proStaffStatus
    }`);
  } catch (error) {
    console.error('Error updating status:', error);
  }
};

export const checkAndUpdateStatus = async (bookingId) => {
	try {
	  const database = getDatabase();
  
	  // Fetch the current waivers and status
	  const bookingRef = ref(database, `bookings/${bookingId}`);
	  const bookingSnapshot = await get(bookingRef);
    const inquiryData = await getInquiryDataById(bookingSnapshot.val().inquiryId);
    console.log('inquiryData from check and update');
    console.log(inquiryData);

	  const currentWaivers = bookingSnapshot.val().waiversFilled || { adult: 0, minor: 0 };
	  const currentStatus = bookingSnapshot.val().status;
  
	  // Check if both adult and minor waivers are completed
	  if (currentWaivers.adult === (inquiryData[0].inquiryData.adults + inquiryData[0].inquiryData.seniors) && currentWaivers.minor === (inquiryData[0].inquiryData.infants + inquiryData[0].inquiryData.children) ) {
		// Update the status to 'Paid'
		await update(bookingRef, { status: 'Paid' });
		console.log(`Status updated to Paid`);
	  } else {
		console.log(`Waivers are not completed yet`);
	  }
	} catch (error) {
	  console.error('Error checking and updating status:', error);
	}
  };

  
export const updateWaiversFilled = async (bookingId, type) => {
	try {
	  const database = getDatabase();
  
	  // Fetch the current waivers object
	  const bookingRef = ref(database, `bookings/${bookingId}`);
	  const bookingSnapshot = await get(bookingRef);
	  const currentWaivers = bookingSnapshot.val().waiversFilled || { adult: 0, minor: 0 };
  
	  currentWaivers[type] = currentWaivers[type] + 1;
  
	  // Update the database with the modified waivers object
	  await update(bookingRef, { waiversFilled: currentWaivers });
  
	  console.log(`status updated`);
	} catch (error) {
	  console.error('Error updating booking status:', error);
	}
  };

export const updateInquiryStatus = async (inquiryId, status) => {
  try {
    const database = getDatabase();
    // Assuming you are using Firebase Realtime Database
    await update(ref(database, `inquiries/${inquiryId}`), { status });
    console.log(`Inquiries ${inquiryId} status updated to ${status}`);
  } catch (error) {
    console.error('Error updating inquiry status:', error);
  }
};

export const updateWithdrawalRequestStatus = async (withdrawalId, status) => {
  try {
    const database = getDatabase();
    // Assuming you are using Firebase Realtime Database
    await update(ref(database, `withdrawals/${withdrawalId}`), { status });
    console.log(`Withdrawal ${withdrawalId} status updated to ${status}`);
  } catch (error) {
    console.error('Error updating booking status:', error);
  }
};

export const updateHeldPaymentStatus = async (paymentId, status) => {
  try {
    const database = getDatabase();
    // Assuming you are using Firebase Realtime Database
    await update(ref(database, `payments/${paymentId}`), { status });
    console.log(`Booking ${paymentId} status updated to ${status}`);
  } catch (error) {
    console.error('Error updating booking status:', error);
  }
};

// export const updateEventStatus = async (eventId, color) => {
//   try {
//     const database = getDatabase();
//     // Assuming you are using Firebase Realtime Database
//     await update(ref(database, `events/${eventId}`), { color });
//     console.log(`Event ${eventId} status updated to ${color}`);
//   } catch (error) {
//     console.error('Error updating event color:', error);
//   }
// };