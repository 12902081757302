import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import './App.css';
import AuthProvider, { useAuth } from "./contexts/AuthContext";
import Frontend from "./Components/frontend/Frontend";
import Backend from "./Components/backend/Backend";
import UserType from  "./Components/userType";
import OwnerDashboard from "./Components/OwnerDashboard";
import About from "./Components/About";
import Settings from "./Components/backend/Settings";
import Search from "./Components/Search";
import LandingPage from "./Components/LandingPage";
import SignupOwner from "./Components/SignupOwner";
import Login from "./Components/login";
import AuthLayout from "./Components/Auth/AuthLayout";
import Bookings from "./Components/backend/Bookings";
import SignupRenter from "./Components/SignupRenter";
import CreateListing from "./Components/createListing";
import Payments from "./Components/backend/Payments";
import MyListings from "./Components/backend/MyListings";
import AddListing from "./Components/backend/AddListing";
import Inbox from "./Components/Inbox";
import Users from "./Components/backend/Users";
import AddUser from "./Components/backend/AddUser";
import Profile from "./Components/backend/Profile";
import ChatBox from "./Components/ChatBox";
import ThankYou from "./Components/ThankYou";
import Listing from "./Components/backend/Listing";
import FrontEndListings from "./Components/backend/FrontEndListings";
import SingleListing from "./Components/SingleListing";
import ListingDetail from "./Components/frontend/ListingDetail";
import { getUserByID } from "./Components/utils";
import { useEffect, useState } from "react";
import PendingApproval from "./Components/frontend/PendingApproval";
import User from "./Components/backend/User";
import BookingPayment from "./Components/backend/BookingPayment";
import Email from "./Components/backend/Email";
import Waivers from "./Components/backend/Waivers";
import AddWaiver from "./Components/backend/AddWaiver";
import Checklist from "./Components/Checklist";
import BoatOwners from "./Components/backend/BoatOwners";
import Event from "./Components/backend/Event";
import StartTrip from "./Components/backend/StartTrip";
import EndTrip from "./Components/backend/EndTrip";
import Listings from "./Components/backend/Listings";
import Disputes from "./Components/backend/Disputes";
import Pricings from "./Components/frontend/Pricings";
import FAQ from "./Components/frontend/FAQ";
import ContactUs from "./Components/frontend/ContactUs";
import Withdrawal from "./Components/backend/Withdrawal";
import GetVerified from "./Components/frontend/GetVerified";
import TripWaivers from "./Components/backend/TripWaivers";
import AdultWaiver from "./Components/backend/AdultWaiver";
import MinorWaiver from "./Components/backend/MinorWaiver";
import Cancellation from "./Components/frontend/Cancellation";
import LaunchLoadChecklists from "./Components/frontend/LaunchLoadChecklists";
import PendingListings from "./Components/backend/PendingListings";
import PendingUsers from "./Components/backend/PendingUsers";
import PendingBoatOwners from "./Components/backend/PendingBoatOwners";
import BecomePro from "./Components/backend/BecomePro";
import PendingProMembers from "./Components/frontend/PendingProMembers";
import PrivacyPolicy from "./Components/frontend/PrivacyPolicy";
import CookiePolicy from "./Components/frontend/CookiePolicy";
import ClientRelationship from "./Components/frontend/ClientRelationship";
import RejectedApproval from "./Components/frontend/RejectedApproval";
import EditWaiver from "./Components/backend/EditWaiver";
import Boats from "./Components/frontend/Boats";
import Coupons from "./Components/backend/Coupons";
import WaiverThankYou from "./Components/frontend/WaiverThankYou";
import MyWaiver from "./Components/frontend/MyWaiver";
import MyWaiverForm from "./Components/frontend/MyWaiverForm";
import StartTripWrapped from "./Components/backend/StartTripWrapped";
import ViewFillWaivers from "./Components/frontend/ViewFillWaivers";
import DemoBookingPayment from "./Components/backend/DemoBookingPayment";
import ForgotPassword from "./Components/ForgotPassword";
import HowTo from "./Components/frontend/HowTo";
import Tip from "./Components/backend/Tip";
import DatepickerCompo from "./Components/DatepickerCompo";
import How from "./Components/backend/How";
import GetUserVerified from "./Components/backend/GetUserVerified";
function App() {  
  const [userStatus, setUserStatus] = useState(null);
  
  function PrivateRoute({ children }) {
    const auth = useAuth();
    useEffect(() => {
      const fetchUserData = async () => {
        try {
          const userData = await getUserByID(auth.currentUser.uid);
          setUserStatus(userData.status);
        } catch (error) {
          // Handle error, e.g., redirect to an error page
          console.error("Error fetching user data", error);
        }
      };
  
      if (auth.currentUser) {
        fetchUserData();
      }
    }, [auth.currentUser]);
  
    if (!auth.currentUser) {
      // If user is not logged in, redirect to the login page
      return <Navigate to="/auth" />;
    }
  
    // if (userStatus === "Pending") {
    //   // If status is pending, redirect to the approval-pending page
    //   return <Navigate to="/approval-pending" />;
    // }
  
    // Allow access to the dashboard if user is logged in and status is not "Pending"
    return children;
  }
  

  function PublicRoute({ children }) {
    const auth = useAuth();
    return auth.currentUser ? <Navigate to="/dashboard" /> : children;
  }

  // function PublicRoute({ children }) {
  //   const auth = useAuth();
  //   return auth.currentUser && userStatus === 'Pending' ? <Navigate to="/dashboard" /> : children;
  // }

  return (
    <AuthProvider>
    <Router>
      <Routes>
        <Route path="/" element={<Frontend/>}>
            <Route index element={<UserType/>}/>
            <Route path="home" element={<LandingPage/>}/>
            <Route path="tip/:dynamicId" element={<Tip/>}/>
            <Route path="about" element={<About/>}/>
            <Route path="faq" element={<FAQ/>}/>
            <Route path="pricing" element={<Pricings/>}/>
            <Route path="contact" element={<ContactUs/>}/>
            <Route path="send-email" element={<Email/>}/>
            <Route path="thank-you" element={<ThankYou/>}/>
            <Route path="ListingDetail" element={<ListingDetail/>}/>
            <Route path="listings" element={<Boats />} />
            <Route path="how-it-works" element={<HowTo />} />
            <Route path="boats" element={< Listings />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="cookie-policy" element={<CookiePolicy />} />
            <Route path ="client-relationship" element={<ClientRelationship/>}/>
            <Route path="cancellation" element={<Cancellation />} />
            <Route path="launch-load-checklists" element={<LaunchLoadChecklists />} />
            <Route path="listing/:dynamicId" element={<SingleListing />} />
            <Route path="boat-listings" element={<FrontEndListings />} />
            <Route path="approval-pending" element={<PendingApproval />} />
            <Route path="approval-rejected" element={<RejectedApproval />} />
            <Route path="get-verified" element={<GetVerified />} />
            <Route path="waivers/adultWaiver/:dynamicId" element={<AdultWaiver/>}/>
            <Route path="my-waiver/:dynamicId" element={<MyWaiver/>}/>
            <Route path="my-waiver" element={<MyWaiverForm/>}/>
            <Route path="waivers/minorWaiver/:dynamicId" element={<MinorWaiver/>}/>
            <Route path="waivers/thank-you" element={<WaiverThankYou/>}/>
            <Route path="waivers/view-waivers" element={<ViewFillWaivers/>}/>
            <Route path="datepicker" element={<DatepickerCompo/>}/>
        </Route>
        <Route path="/dashboard" element={<PrivateRoute><Backend/></PrivateRoute>}>
          <Route index element={<Bookings/>}/>
          <Route path="setting" element={<Settings/>}/>
          <Route path="bookings" element={<Bookings/>}/>
          <Route path="payments" element={<Payments/>}/>
          <Route path="disputes" element={<Disputes/>}/>
          <Route path="how" element={<How/>}/>
          <Route path="withdrawals" element={<Withdrawal/>}/>
          <Route path="booking-payment" element={<BookingPayment/>}/>
          <Route path="demo-booking-payment" element={<DemoBookingPayment/>}/>
          <Route path="my-listings" element={<MyListings/>}/>
          <Route path="add-listing" element={<AddListing/>}/>
          <Route path="get-verified" element={<GetUserVerified/>}/>
          <Route path="inbox" element={<Inbox/>}/>
          <Route path="users" element={<Users/>}/>
          <Route path="user/:dynamicId" element={<User/>} />
          <Route path="boat-owners" element={<BoatOwners/>}/>
          <Route path="boat-owners/:dynamicId" element={<User/>} />
          <Route path="add-user" element={<AddUser/>}/>
          <Route path="profile" element={<Profile/>}/>
          <Route path="thank-you" element={<ThankYou/>}/>
          <Route path="waivers" element={<Waivers/>}/>
          <Route path="edit-waiver/:dynamicId" element={<EditWaiver/>}/>
          <Route path="add-waiver" element={<AddWaiver/>}/>
          <Route path="startTrip/:dynamicId" element={<StartTripWrapped/>}/>
          <Route path="waivers/:dynamicId" element={<TripWaivers/>}/>
          <Route path="endTrip/:dynamicId" element={<EndTrip/>}/>
          <Route path="listing/:dynamicId" element={<Listing />} />
          <Route path="event/:dynamicId" element={<Event />} />
          <Route path="pro-staff" element={<BecomePro />} />
          <Route path="pending-listings" element={<PendingListings />} />
          <Route path="pending-renters" element={<PendingUsers />} />
          <Route path="pending-boat-owners" element={<PendingBoatOwners />} />
          <Route path="pending-pro-members" element={<PendingProMembers />} />
          <Route path="coupons" element={<Coupons />} />
        </Route>
        <Route path="/auth" element={<PublicRoute><AuthLayout/></PublicRoute>}>
          <Route index element={<PublicRoute><Login/></PublicRoute>}/>
          <Route path="forgot-password" element={<PublicRoute><ForgotPassword/></PublicRoute>}/>
          <Route path="signup-owner" element={<PublicRoute><SignupOwner/></PublicRoute>}/>
          <Route path="signup-renter" element={<PublicRoute><SignupRenter/></PublicRoute>}/>
        </Route>
      </Routes>
    </Router>
    </AuthProvider>
  );
}

export default App;
