import React, { useEffect, useState } from 'react';
import { becomeProMemberRequestAdminEmailBody, sendEmail, becomeProMemberRequestSentEmailBody, getBecomeProQuestions, getCurrentUserDetails, getOwnerMeta, getReferralCodeByUserId } from '../utils';
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { getDatabase, push, ref, set, update } from 'firebase/database';
import { Link } from 'react-router-dom';
import ReferralComponent from './ReferralComponent';


export default function How() {
    const [currentUser, setCurrentUser] = useState([]);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const userDetails = await getCurrentUserDetails();
        setCurrentUser(userDetails);
       
      } catch (error) {
        console.error('Error fetching:', error);
      }
    };

    fetchUserDetails();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>How the platform works</h1>
          </div>
        </div>
      </div>
      <Row>
        <Col>
            {currentUser ? (
                currentUser.role === "Renter" ? (
                    <>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <Card.Body>
                            <Card.Title>How to send an inquiry.</Card.Title>
                            <Card.Text>
                                Tutorial below shows how you can send an inquiry to a listing that you wish to rent.
                            </Card.Text>
                            <video className="how-to-video" controls width="800" playsinline>
                                <source src="https://www.bbrbassboatrentals.com/videos/Send_Inquiry.mp4" type="video/mp4" />
                            </video>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <Card.Body>
                            <Card.Title>How to view and accept an offer.</Card.Title>
                            <Card.Text>
                            This is how you view the offer that you have received for your inquiry and accept it.
                            </Card.Text>
                            <video className="how-to-video" controls width="800" playsinline>
                                <source src="https://www.bbrbassboatrentals.com/videos/View_Accept_Offer.mp4" type="video/mp4" />
                            </video>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    </>
                ) : currentUser.role === "Boat Owner" ? (
                    <>
                        <Row>
                          <Col md={12}>
                            <Card>
                              <Card.Body>
                                <Card.Title>How to list your boat.</Card.Title>
                                <Card.Text>
                                    How you list your boat for rent on the platform.
                                </Card.Text>
                                <video className="how-to-video" controls width="800" playsinline>
                                    <source src="https://www.bbrbassboatrentals.com/videos/List_Boat.mp4" type="video/mp4" />
                                </video>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                      <Col md={12}>
                        <Card>
                          <Card.Body>
                            <Card.Title>How to send an inquiry.</Card.Title>
                            <Card.Text>
                                Tutorial below shows how you can send an inquiry to a listing that you wish to rent.
                            </Card.Text>
                            <video className="how-to-video" controls width="800">
                                <source src="https://www.bbrbassboatrentals.com/videos/Send_Inquiry.mp4" type="video/mp4" />
                            </video>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <Card.Body>
                            <Card.Title>How to view and accept an offer.</Card.Title>
                            <Card.Text>
                            This is how you view the offer that you have received for your inquiry and accept it.
                            </Card.Text>
                            <video className="how-to-video" controls width="800" playsinline>
                                <source src="https://www.bbrbassboatrentals.com/videos/View_Accept_Offer.mp4" type="video/mp4" />
                            </video>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                        <Row>
                          <Col md={12}>
                            <Card>
                              <Card.Body>
                                <Card.Title>How to create an offer.</Card.Title>
                                <Card.Text>
                                The following tutorial is on how to create an offer after a renter has sent an inquiry for your listing.
                                </Card.Text>
                                <video className="how-to-video" controls width="800" playsinline>
                                    <source src="https://www.bbrbassboatrentals.com/videos/Create_Offer.mp4" type="video/mp4" />
                                </video>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                        </Row>
                    </>
                ) : null
            ) : null}
        </Col>
      </Row>
    </>
  );
}
