import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { FaThumbsUp, FaUserFriends } from 'react-icons/fa'
import { FaAnchor, FaTrailer  } from "react-icons/fa";
import { Link } from 'react-router-dom'
import Brands from './Brands'
import MyListings from './backend/FrontEndListings'
import { GiSpeedBoat, GiShipWheel, GiFishing   } from "react-icons/gi";
import FloatingButtons from './frontend/FloatingButtons';
import GoogleReviews from './frontend/GoogleReviews';

export default function LandingPage() {

  return (
    <>
    <FloatingButtons/>
    <section className='heroSection'>
      <video className='heroVideo' autoPlay muted loop>
        <source src='https://www.bbrbassboatrentals.com/assets/uploads/sites/153/2023/03/hero-section-video.mp4' type='video/mp4' />
      </video>
      <img className='hero-img' src="https://bbrbassboatrentals.com/assets/backend/images/logo.webp"/>
      
      {/* <Row>
        <Col className='d-flex align-items-center justify-content-center heroContent'>
          <img className='overlay' src='/assets/frontend/images/overlay.webp'/>
        </Col>
      </Row> */}
    </section>
  
    <section className='cs-bg-dark py-4 cs-section home-about'>
      <Container>
        <Row className='mb-3'>
          <Col className='p-0'>
            <img width={160} src="/assets/backend/images/logo.webp"/>
            <h1 className='fw-bold text-white mt-3'>HOME OF THE TOURNAMENT READY BASS BOAT!</h1>
            <p className='text-white'>Do you own a tournament ready bass boat that you would like to rent through the BBR booking platform? We have a database of qualified bass boat operators that would like to rent your bass boat. Would you like to earn an income on that depreciating asset?</p>
          </Col>
        </Row>
        <Row>
          <Col md={7} xs={12} className='p-0 text-left reviews-home'>
            <GoogleReviews/>
          </Col>
          <Col md={5} className='p-0'>
            {/* <Row className='mb-4 mt-4'>
              <Col md={4} xs={4} className='d-flex justify-content-center'>
                <Row className='p-2'>
                  <Col xs={4} className='d-flex align-items-center icon-col'>
                    <FaThumbsUp className='text-white' style={{fontSize: '46px'}}/>
                  </Col>
                  <Col xs={8}>
                    <h2 className='text-white fw-bold'>466+</h2>
                    <h5 className='text-white stats-heading'>Experience</h5>
                  </Col>
                </Row>
              </Col>
              <Col md={4} xs={4} className='d-flex justify-content-center'>
                <Row className='p-2'>
                  <Col xs={4} className='d-flex align-items-center icon-col'>
                    <FaAnchor className='text-white' style={{fontSize: '46px'}}/>
                  </Col>
                  <Col xs={8}>
                    <h2 className='text-white fw-bold'>46+</h2>
                    <h5 className='text-white stats-heading'>Bass Boats</h5>
                  </Col>
                </Row>
              </Col>
              <Col md={4} xs={4} className='d-flex justify-content-center'>
                <Row className='p-2'>
                  <Col xs={4} className='d-flex align-items-center icon-col'>
                    <FaUserFriends className='text-white' style={{fontSize: '46px'}}/>
                  </Col>
                  <Col xs={8}>
                    <h2 className='text-white fw-bold'>100+</h2>
                    <h5 className='text-white stats-heading'>Happy Clients</h5>
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <Row>
              <Col>
                <img className='rounded' width={'100%'} src="/assets/frontend/images/bbrboat2.jpg"/>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>


    <section className='cs-bg-dark py-4 cs-section'>
      <Container>
        <Row>
          <Col md={6} className='p-0 mt-2'>
            <h1 className='fw-bold text-white mt-2'>Our Boats</h1>
            <Link to="/listings" className='btn btn-light btn-outline-light'>Learn More</Link>
          </Col>
          <Col md={6} className='p-0 mt-2'>
            <p className='text-white'>At BBR Bass Boat Rentals, we take pride in offering a fleet of high-performance bass boats that redefine the standards of tournament-ready excellence. Our commitment to delivering an unparalleled angling experience is reflected in the meticulous selection of our boats and the features they boast.</p>
          </Col>
        </Row>
        <Row className='p-0'>
          <MyListings/>
        </Row>
      </Container>
    </section>


    <section className='cs-bg-dark py-4 cs-section statsSection'>
      <Container>
        <Row>
          <Col md={3} className='statsCol br-1'>
            <GiSpeedBoat className='text-white mb-4' style={{fontSize: '90px'}}/>
            <h4 className='text-white fw-bold'>Tournament Ready Bass Boats</h4>
            <p className='text-white'>Elevate your angling game with our top-tier bass boats, meticulously equipped for tournament-level performance.</p>
            
          </Col>
          <Col md={3} className='statsCol br-1'>
            <FaTrailer  className='text-white mb-4' style={{fontSize: '90px'}}/>
            <h4 className='text-white fw-bold'>Trailer Rental</h4>
            <p className='text-white'>Seamless transportation is at your fingertips with our trailer rental service, ensuring your bass boat reaches any freshwater destination hassle-free.</p>
          </Col>
          <Col md={3} className='statsCol br-1'>
            <GiFishing  className='text-white mb-4' style={{fontSize: '90px'}}/>
            <h4 className='text-white fw-bold'>Delivery Available</h4>
            <p className='text-white'>Convenience meets flexibility – opt for our delivery service, bringing the tournament-ready bass boat directly to your preferred inland freshwater location.</p>
          </Col>
          <Col md={3} className='statsCol'>
            <GiShipWheel  className='text-white mb-4' style={{fontSize: '90px'}}/>
            <h4 className='text-white fw-bold'>Rent By The Hour, By The Day Or Overnight Up To 6 Days</h4>
            <p className='text-white'>Tailor your experience with hourly, daily, or overnight rentals, granting you the freedom to enjoy the water for up to 6 days on your terms.</p>
          </Col>
        </Row>
      </Container>
    </section>


    <section className='cs-bg-dark py-4 cs-section'>
      <Container>
        <Row>
          <Col md={4}>
            <h2 className='fw-bold text-white mt-2'>Our Partners</h2>
            <p className='text-white'>Proud to collaborate with industry-leading partners who share our commitment to excellence</p>
          </Col>
          <Col md={8}>
            <Row className='partners-brands'>
              <Col md={6} className='d-flex align-items-center justify-content-center'>
                <Link to='https://www.texasfishingforum.com/' target='_blank'>
                  <img width={'100%'} height={'auto'} src='/assets/frontend/images/partner1.webp'/>
                </Link>
              </Col>
              <Col md={6} className='d-flex align-items-center justify-content-center'>
                <Link to='https://conroebass.com/' target='_blank'>
                  <img width={'100%'} height={'auto'} src='/assets/frontend/images/partner3.webp'/>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </section>

    </>
  )
}
