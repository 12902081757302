import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { getActiveListings, getAllAttributes, getEventsByID, getEventsByListingId, getAllBoatTypes } from '../utils';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/DateRangePicker/styles/index.css';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
  } from 'react-places-autocomplete';
import { addDays } from 'date-fns';
import GoogleMaps from '../GoogleMaps';
import { MultiSelect } from "react-multi-select-component";


export default function Boats() {
    const [listings, setListings] = useState([]);
    const [boatFilters, setBoatFilters] = useState([]);
    const [boatTypesFilters, setBoatTypesFilters] = useState([]);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [selectedBoatTypes, setSelectedBoatTypes] = useState([]);
    const [locationNames, setLocationNames] = useState({});
    const [dateRange, setDateRange] = useState([null, null]);
    const [boatLocations, setBoatLocations] = useState([]);
    const [searchAddress, setSearchAddress] = useState('');
    const [searchedLocation, setSearchedLocation] = useState(null);
    const [searchedLocationGeoData, setSearchedGeoDataLocation] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [listingsPerPage] = useState(9);
    const [selectedBoatTypeOptions, setselectedBoatTypeOptions] = useState([]);
    const [boatTypeOptions, setBoatTypeOptions] = useState([]);
    const [selectedFiltersOptions, setSelectedFiltersOptions] = useState([]);
    const [filtersOptions, setFilterOptions] = useState([]);
    

    const handleDateRangeChange = (value) => {
        if(value == null){
            setDateRange([]);
        }else{
            setDateRange(value);
        }
      };
    
      const today = new Date();
      const tomorrow = addDays(new Date(), 1);
      const yesterday = addDays(new Date(), -1);
      const calculateDistance = (locationA, locationB) => {
        if (!locationA || !locationB) return;
    
        const lat1 = locationA.lat;
        const lon1 = locationA.lng;
        const lat2 = locationB.lat;
        const lon2 = locationB.lng;
    
        // Calculate distance using Haversine formula
        const R = 6371 * 1000;
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
            Math.cos(lat2 * (Math.PI / 180)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
    
        return distance;
        };
    
    

      useEffect(() => {
        const fetchListings = async () => {
            try {
                const listings = await getActiveListings();
                const allAttributes = await getAllAttributes();
                const allBoatTypes = await getAllBoatTypes();
                const optionsArray = Object.values(allAttributes[2]);
    
                console.log("allBoatTypes", allBoatTypes);
                console.log("optionsArray", optionsArray);
    
                const listingEventsPromises = listings.map(async (listing) => {
                    const boatAddress = await getLocationName(listing.storageAddress.lat, listing.storageAddress.lng);
                    const boatGeoData = await geocodeByAddress(boatAddress);
                    const latLng = await getLatLng(boatGeoData[0]);

                    const newLocationObject = {
                        name: listing.listingTitle,
                        lat: latLng.lat,
                        lng: latLng.lng,
                        link: 'listing/' + listing.id,
                        boatType: listing.boatType,
                        icon: 'https://bbrbassboatrentals.com/static/media/loading.33ed2d822776ba990a07.png'
                    };

                    setBoatLocations(prevLocations => [...prevLocations, newLocationObject]);

                    return {
                        ...listing,
                        features: listing.features || [],
                        listingAddress: latLng || [],
                        listingGeoData: boatGeoData || []
                    };
                });
    
                const updatedListings = await Promise.all(listingEventsPromises);
    
                console.log("listings", updatedListings);
                setListings(updatedListings);
                
                console.log("allBoatTypes",allBoatTypes);

                const boatTypeOptions = allBoatTypes.map(boatType => ({ label: boatType, value: boatType }));
                const newFiltersOptions = optionsArray.map(option => ({ label: option, value: option }));
                setFilterOptions(prevOptions => [...newFiltersOptions]);
                setBoatTypeOptions(prevOptions => [...boatTypeOptions]);

                setBoatTypesFilters(allBoatTypes);
                setBoatFilters(optionsArray);
    
                // Fetch location names for each listing
                const namesPromises = updatedListings.map(listing => getLocationName(listing.storageAddress.lat, listing.storageAddress.lng));
                const names = await Promise.all(namesPromises);
                const locationNamesMap = {};
                updatedListings.forEach((listing, index) => {
                    locationNamesMap[listing.id] = names[index];
                });
                setLocationNames(locationNamesMap);
            } catch (error) {
                console.error('Error fetching listings:', error);
            }
        };
        fetchListings();
    }, []);
    

    function isDateRangeOverlap(listingEvents, selectedStart, selectedEnd) {
        // Iterate over each event in listingEvents
        for (let i = 0; i < listingEvents.length; i++) {
            const event = listingEvents[i];
    
            // Convert start and end strings to Date objects
            const eventStart = new Date(event.start);
            const eventEnd = new Date(event.end);
    
            // Check if event falls within the selected range
            if (eventStart <= selectedEnd && eventEnd >= selectedStart) {
                return true; // Return true if there's an overlap
            }
        }
        return false; // Return false if no overlap found
    }
    

    const getLocationDetails = async (lat, lng) => {
        try {
            const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`);
            const data = await response.json();
            if (data.status === 'OK') {
                const address = data.results[0];
                return address;
            } else {
                return ('Reverse geocoding failed:', data.status);
            }
        } catch (error) {
            return ('Error fetching location details:', error);
        }
    };
    

    
    const filteredListings = listings.filter(listing => {
        const selectedStart = dateRange[0];
        const selectedEnd = dateRange[1];
        const listingAddress = listing.listingAddress;
        const listingGeoData = listing.listingGeoData;
        const searchedGeoData = searchedLocation;
        const searchedLocationGeo = searchedLocationGeoData;

        var isChildOfSearched = true;
        var isNearbyLocation = true;
        var haveAttributes = true;
        var hasMatchingBoatTypeValue = true;
        var isAvalaible = true;
        let isBoatTypeSelected;
        let isFilterSelected;

        if(searchedGeoData != undefined){

            console.log("searchedLocationGeo", searchedLocationGeo);
            console.log("listingGeoData", listingGeoData);
            
            if(searchedLocationGeo[0].types[0]){

                const addressType = searchedLocationGeo[0].types[0];
                
                const countryComponent = listingGeoData[0].address_components.find(component =>
                    component.types.includes(addressType)
                );
                const countryComponentSearched = searchedLocationGeo[0].address_components.find(component =>
                    component.types.includes(addressType)
                );

                if (countryComponent) {
                    const countryName = countryComponent.long_name;
                    const countryComponentSearch = countryComponentSearched.long_name;

                    console.log("countryComponent",countryName,countryComponentSearch);
                    if(countryName == countryComponentSearch){
                        isChildOfSearched = true;
                    }else{
                        isChildOfSearched = false;
                    }
                } else {
                    isChildOfSearched = false;
                    console.log("Country not found in address components.");
                }
            }

            if(searchedGeoData.lat != undefined || searchedGeoData.lng != undefined){

                const distance = calculateDistance(searchedLocation,listingAddress);

                console.log("searchedLocation new updated new", searchedGeoData);
                console.log("listingAddress", listingAddress);
                console.log("distance",distance);
                
                if (distance < 160934) {
                    isNearbyLocation = true;
                }else{
                    isNearbyLocation = false;
                }
            }
        }

        if(selectedStart != undefined && selectedEnd != undefined){
            const isOverlap = isDateRangeOverlap(listing.listingEvents, selectedStart, selectedEnd);
            if(isOverlap == true){
                isAvalaible = false;
            }
        }

        haveAttributes = listing.features && selectedAttributes.every(attribute => listing.features.includes(attribute));

        if(selectedBoatTypes.length != 0){
            hasMatchingBoatTypeValue = selectedBoatTypes.includes(listing.boatType);
        }

        console.log("selectedBoatTypeOptionsFilters",selectedBoatTypeOptions);

        

        if (selectedBoatTypeOptions.length === 0) {
            isBoatTypeSelected = true;
        } else {
            // Check if boatType exists in selectedBoatTypeOptions
            isBoatTypeSelected = selectedBoatTypeOptions.some(option => option.value === listing.boatType);
        }
        
        if (selectedFiltersOptions.length === 0) {
            isFilterSelected = true;
        } else {
            // Check if boatType exists in selectedBoatTypeOptions
            isFilterSelected = selectedFiltersOptions.some(option => option.value === listing.boatType);
        }

        let anyOptionSelected;

        if (selectedFiltersOptions.length === 0) {
            anyOptionSelected = true;
        } else {
            // Initialize an array to store the boolean values for each option
            const isOptionSelected = selectedFiltersOptions.map(option => {
                // Check if the value of the option exists in listing.features
                return listing.features.includes(option.value);
            });
        
            // Check if any option is selected
            anyOptionSelected = isOptionSelected.includes(true);
        }
    
        if(isAvalaible != false){
            console.log("Listing is available");

            if(isBoatTypeSelected != false){
                if(anyOptionSelected != false){
                    if(searchAddress){
                        if(isNearbyLocation != false){
                            console.log("Locaton is nearby");
                        }else{
                            if(isChildOfSearched != false){
                                console.log("Locaton is child of searched", isChildOfSearched);
                            }else{
                                console.log("Locaton is not child of searched", isChildOfSearched);
                                return false;
                            }
                        }
                    }
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }else{
            return false;
        }

        return true;
        
    });    
      
      // Get current listings
    const indexOfLastListing = currentPage * listingsPerPage;
    const indexOfFirstListing = indexOfLastListing - listingsPerPage;
    const currentListings = filteredListings.slice(indexOfFirstListing, indexOfLastListing);

    // Change page
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);

    const containerStyle = {
        minHeight: '350px',
        borderRadius: '20px',
        padding: '3px',
        backgroundSize: 'cover',
        margin: '0px',
        backgroundPosition: 'center',
    };

    const getLocationName = async (lat, lng) => {
        try {
            const dropOffResponse = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBfEcoyW9DM7QQWdV3oTjevrfyhX5n5qqg`
            );

            const dropOffdata = await dropOffResponse.json();
            const dropoffName =
                dropOffdata.results[0]?.formatted_address || 'Location not found';
            return dropoffName;
        } catch (error) {
            console.error('Error fetching location name:', error);
            return null;
        }
    };

    const handleCheckboxChange = (option) => {
        setSelectedAttributes((prevSelected) =>
          prevSelected.includes(option)
            ? prevSelected.filter((attr) => attr !== option)
            : [...prevSelected, option]
        );
    };

    const handleBoatTypeCheckboxChange = (option) => {
        setSelectedBoatTypes((prevSelected) =>
          prevSelected.includes(option)
            ? prevSelected.filter((attr) => attr !== option)
            : [...prevSelected, option]
        );
    };

      const handleSearchInputChange = async (newAddress) => {
        if (!newAddress) {
            setSearchAddress('');
        } else {
            try {
                const results = await geocodeByAddress(newAddress);
                const latLng = await getLatLng(results[0]);
                setSearchedLocation(latLng);
                setSearchedGeoDataLocation(results);
                setSearchAddress(newAddress);
            } catch (error) {
                console.error('Error fetching location:', error);
            }
        }
    };

    return (
        <>
            <section className='cs-bg-dark py-4 cs-section'>
                <div className='container'>
                    <div className='row'>
                        <div className='col'>
                            <h1 className='fw-bold text-white'>Our Boats</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <Col xs={12} md={12} className='mt-4'  style={{height: '400px'}}>
                            {boatLocations && boatLocations.length > 0 && <>
                                <GoogleMaps boatLocations={boatLocations}/> 
                            </>}  
                        </Col>
                        {/* <Col xs={12} md={6}>
                            <Row className='mb-3'>
                                <Col xs={12} md={6} className='mt-4'>
                                        <div className="card p-4 filter-card">
                                            <div className="attributes">
                                                <h5>Filter by boat types</h5>
                                                {boatTypesFilters.map((option, index) => (
                                                    <div className="form-check" key={index}>
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        id={`boatTypeCheckbox-${index}${option}`}
                                                        value={option}
                                                        onChange={() => handleBoatTypeCheckboxChange(option)}
                                                        checked={selectedBoatTypes.includes(option)}
                                                      />
                                                      <label
                                                        className="form-check-label"
                                                        htmlFor={`boatTypeCheckbox-${index}${option}`}
                                                      >
                                                        {option}
                                                      </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                </Col>
                                <Col xs={12} md={6} className='mt-4'>
                                        <div className="card p-4 filter-card">
                                            <div className="attributes">
                                                <h5>Filter by boat features</h5>
                                                <Row>
                                                    <Col>
                                                        {boatFilters.map((option, index) => (
                                                            <div className="form-check" key={index} >
                                                              <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id={`checkbox-${index}`}
                                                                value={option}
                                                                onChange={() => handleCheckboxChange(option)}
                                                                checked={selectedAttributes.includes(option)}
                                                              />
                                                              <label
                                                                className="form-check-label"
                                                                htmlFor={`checkbox-${index}`}
                                                              >
                                                                {option}
                                                              </label>
                                                            </div>
                                                        ))}
                                                    </Col>
                                                </Row>
                                                
                                            </div>
                                        </div>
                                </Col>
                            </Row>
                        </Col> */}
                    </div>
                    <div className="row mt-3">
                        <Row className='dateRangePicker'>
                            <Col xs={12} md={3} className='d-flex flex-column mb-2'>
                                <DateRangePicker
                                  value={dateRange}
                                  onChange={handleDateRangeChange}
                                  placeholder="Choose your date"
                                  character=' to '
                                  ranges={[
                                    {
                                        label: 'Today',
                                        value: [today, addDays(today, 0)] // You can adjust the number of days as needed
                                    },
                                    {
                                        label: 'Next 3 Days',
                                        value: [tomorrow, addDays(tomorrow, 2)] // You can adjust the number of days as needed
                                    },
                                    {
                                      label: 'Next Seven Days',
                                      value: [tomorrow, addDays(today, 7)] // You can adjust the number of days as needed
                                    }
                                  ]}
                                  disabledDate={date => date < yesterday}
                                />
                            </Col>
                            <Col xs={12} md={3} className='d-flex flex-column mb-2'>
                                <PlacesAutocomplete
                                  value={searchAddress}
                                  onChange={(newAddress) => {
                                    setSearchAddress(newAddress);
                                    handleSearchInputChange(newAddress);
                                  }}
                                  className="form-control form-control-solid-bordered"
                                >
                                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                    <div>
                                      <div className="input-group">
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Search by Lake / Location...',
                                            className: 'location-search-input form-control',
                                            name: "location"
                                          })}
                                        />
                                      </div>
                                      <div className="autocomplete-dropdown-container">
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map((suggestion) => {
                                          const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                          return (
                                            <div
                                              {...getSuggestionItemProps(suggestion, {
                                                className: 'location-suggestion form-control'
                                              })}
                                            >
                                              <span>{suggestion.description}</span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>
                            </Col>
                            <Col xs={12} md={3} className='d-flex flex-column mb-2'>
                            <MultiSelect
                              options={boatTypeOptions}
                              value={selectedBoatTypeOptions}
                              onChange={setselectedBoatTypeOptions}
                              disableSearch="true"
                              placeholder="Select Options"
                              overrideStrings={{
                                selectSomeItems: "Filter by Boat Types...",
                            }}
                            />

                            </Col>
                            <Col xs={12} md={3} className='d-flex flex-column mb-2'>
                            <MultiSelect
                              options={filtersOptions}
                              value={selectedFiltersOptions}
                              onChange={setSelectedFiltersOptions}
                              disableSearch="true"
                              placeholder="Select Options"
                              overrideStrings={{
                                selectSomeItems: "Filter by Features...",
                            }}
                            />

                            </Col>
                        </Row>
                        <Row className='boatListings mt-3'>
                            
                            <Col>
                                <Row>
                                    {currentListings.map(listing => (
                                        <Col md={4} className="mb-4" key={listing.id}>
                                            <Link to={`/listing/${listing.id}`} style={{ textDecoration: 'none' }}>
                                                <div className="row productCard p-3 position-relative" style={{ ...containerStyle, backgroundImage: `url(${listing.featured_image})` }}>
                                                    <div className="overlay"
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            right: 0,
                                                            bottom: 0,
                                                            content: '""',
                                                            backgroundColor: 'rgba(0, 0, 0, 0.22)', // Adjust the color and opacity as needed
                                                        }}
                                                    ></div>
                                                    <div className="col-12" style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'column' }}>
                                                        <p></p>
                                                        <div className="row" style={{ zIndex: '9' }}>
                                                            <div className="col-12"></div>
                                                            <div className="row">
                                                                <h3 style={{ padding: '0px', color: 'white' }}>
                                                                    {listing.listingTitle}
                                                                </h3>
                                                                <p style={{ padding: '0px', color: 'white' }}>
                                                                    {locationNames[listing.id] || 'Loading...'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Col>
                                    ))}
                                </Row>
                                {/* Pagination */}
                                <div className="pagination">
                                    <button
                                        onClick={prevPage}
                                        className={`btn ${currentPage === 1 ? 'btn-light' : 'btn-light'} m-1`}
                                        disabled={currentPage === 1}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        onClick={nextPage}
                                        className={`btn ${currentListings.length < listingsPerPage ? 'btn-light' : 'btn-light'} m-1`}
                                        disabled={currentListings.length < listingsPerPage}
                                    >
                                        Next
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </>
    )
}
