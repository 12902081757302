import React, { useEffect, useState } from 'react';
import { becomeProMemberRequestAdminEmailBody, sendEmail, becomeProMemberRequestSentEmailBody, getBecomeProQuestions, getCurrentUserDetails, getOwnerMeta, getReferralCodeByUserId, sendSMS } from '../utils';
import { Col, Row, Form, Button, Card } from 'react-bootstrap';
import { getDatabase, push, ref, set, update } from 'firebase/database';
import { Link } from 'react-router-dom';
import ReferralComponent from './ReferralComponent';


export default function BecomePro() {
  const [questions, setQuestions] = useState([]);
  const [currentUserId, setCurrentUserId] = useState([]);
  const [ownerMeta, setOwnerMeta] = useState([]);
  const [formData, setFormData] = useState([]);
  const [referralCode, setReferralCode] = useState('');

  const database = getDatabase();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const questions = await getBecomeProQuestions();
        const currentUser = await getCurrentUserDetails();
        const ownerMetaData = await getOwnerMeta(currentUser.userId);
        const referralCode = await getReferralCodeByUserId(currentUser.userId);
        console.log('currentUser.userId');
        console.log(currentUser.userId);
        setOwnerMeta(ownerMetaData);
        setReferralCode(referralCode);
        setQuestions(questions);
        setCurrentUserId(currentUser.userId);
        const initialFormData = questions.map(question => ({
          question: question.questionText,
          answer: ''
        }));
        setFormData(initialFormData);
      } catch (error) {
        console.error('Error fetching Questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    setFormData(prevFormData => {
      const updatedFormData = [...prevFormData];
      updatedFormData[index].answer = value;
      return updatedFormData;
    });
  };

  const renderQuestion = (question, index) => {
    if (question.questionType === 'option') {
      return (
        <Form.Group key={index}>
          <Form.Label>{question.questionText}</Form.Label>
          <div className='d-flex gap-4'>
            {question.options.map((option, optionIndex) => (
              <Form.Check
                key={optionIndex}
                type="radio"
                id={`option-${index}-${optionIndex}`}
                label={option}
                name={`question-${index}`}
                value={option}
                onChange={(e) => handleInputChange(e, index)}
              />
            ))}
          </div>
        </Form.Group>
      );
    } else if (question.questionType === 'text') {
      return (
        <Form.Group key={index}>
          <Form.Label>{question.questionText}</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            name={`question-${index}`}
            value={formData[index]?.answer || ''}
            onChange={(e) => handleInputChange(e, index)}
          />
        </Form.Group>
      );
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const usersRef = ref(database, `ownerMeta/${currentUserId}`);
        await update(usersRef, { proStaffStatus: "Pending"});   
        const submissionRef = push(ref(database, `ownerMeta/${currentUserId}/becomeProRequests`));
        const timestamp = new Date().toISOString();
        await set(submissionRef, { formData, timestamp }); 
        const updatedOwnerMetaData = await getOwnerMeta(currentUserId);
        setOwnerMeta(updatedOwnerMetaData);

        const userData = await getCurrentUserDetails();
        const userName = userData.firstName + ' ' + userData.lastName;
        // const applicantEmailBody = await becomeProMemberRequestSentEmailBody(userName)
        // sendEmail (userData.email, 'Pro Member Application', applicantEmailBody);

        const smsBody = `Thank you for submitting your request to become a pro staff member. We are currently in the process of verifying your answers.\n\nPlease note that due to the high volume of requests, this may take some time.\n\nhttps://bbrbassboatrentals.com`
        const smsResponse = sendSMS(userData.phone, smsBody);
        console.log(smsResponse)
        const adminEmailBody = await becomeProMemberRequestAdminEmailBody(userName)
        sendEmail ('waivers@bbrbassboatrentals.com', 'Pro Member Application', adminEmailBody);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <div className="page-description">
            <h1>Become A Pro Staff</h1>
          </div>
        </div>
      </div>
      <Row>
        <Col>
            {ownerMeta && ownerMeta.proStaffStatus !== undefined ? (
                ownerMeta.proStaffStatus === "Pending" ? (
                    <>
                    <Row>
                      <Col md={12}>
                        <Card>
                          <Card.Body>
                            <Card.Title>Thank You for Applying for Pro Staff Member</Card.Title>
                            <Card.Text>
                              We're checking your request. Please note that due to the high volume of requests, this may take some time.
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    </>
                ) : ownerMeta.proStaffStatus === "Rejected" ? (
                    <>
                        <Row>
                          <Col md={12}>
                            <Card  className='bg-danger'>
                              <Card.Body>
                                <Card.Title className='text-white'>Your Pro Staff Application Was Rejected</Card.Title>
                                <Card.Text className='text-white'>
                                  Unfortunately, your application for Pro Staff membership has been rejected.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                  <Card.Body>
                                    <h3>But don't lose hope, you can apply once again.</h3>
                                    <Form onSubmit={handleSubmit}>
                                        {questions.map((question, index) => renderQuestion(question, index))}
                                        <Button variant="primary" className='mt-4' type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                  </Card.Body>
                                </Card> 
                            </Col>
                        </Row>
                    </>
                ) : ownerMeta.proStaffStatus === "Approved" ? (
                    <>
                        <Row>
                          <Col md={12}>
                            <Card  className='bg-success'>
                              <Card.Body>
                                <Card.Title className='text-white'>Congratulations, Your Now Pro Staff Member</Card.Title>
                                <Card.Text className='text-white'>
                                  Share your referal code with other boat owners upon their signup. You'll get $50 at their first payment.
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <ReferralComponent referralCode={referralCode} />
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </>
                ) : null
                ) : (
                    <>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <h3>Join Our Elite Crew: Become a Pro Staff Member</h3>
                                    <p>Unlock Exclusive Discounts and Reel in Rewards – Refer, Rent, and Rise to Pro Status!</p>
                                    <Form onSubmit={handleSubmit} className='mt-4'>
                                        {questions.map((question, index) => renderQuestion(question, index))}
                                        <Button variant="primary" className='mt-3' type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    </>
            )}
        </Col>
      </Row>
    </>
  );
}
