import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FloatingButtons from './FloatingButtons'

export default function LaunchLoadChecklists() {
    return (
        <>
        <FloatingButtons/>
        <section className='cs-bg-dark py-4 cs-section'>
            <div className='container text-white'>
                <Row>
                    <Col xs={12}>
                        <h1>LAUNCH CHECKLIST</h1>
                        <ul className='text-white'>
                          <li>Truck Lights Low Beam</li>
                          <li>Batteries On</li>
                          <li>Hydraulic Jack Plate Up 1/2 Way & Trim Up</li>
                          <li>Remove Transom Saver</li>
                          <li>Remove Steering Stops</li>
                          <li>Remove Tie Downs</li>
                          <li>Plug Screwed In</li>
                          <li>Power On Light Poles On</li>
                          <li>Kill Switch Engaged</li>
                          <li>Life Jacket Attached To Kill Switch</li>
                          <li>Electronics On</li>
                          <li>Keep Bow Straps Tied Down</li>
                          <li>Back Trailer Into Water Until Front Fender Step Is Submerged</li>
                          <li>Release Bow Straps</li>
                          <li>If Parking Boat At A Dock Use Power Poles And Rope. Park Downwind Of The Dock To Avoid Wind Blown Collision</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h1>LOAD CHECKLIST</h1>
                        <ul className='text-white'>
                          <li>Truck Lights Low Beam</li>
                          <li>Back Trailer Into Water Until Front Fender Step Is Submerged</li>
                          <li>Trim Up Drive Boat Onto Trailer Slowly, Power Up To Engage Front D Clip On Roller.</li>
                          <li>Engage Bow Straps</li>
                          <li>Drive Truck To Parking</li>
                          <li>Unscrew Plug</li>
                          <li>Attach Rear Tie Downs</li>
                          <li>Install Transom Saver</li>
                          <li>Install Steering Stops</li>
                          <li>Hydraulic Jack Plate Down & Trim Down</li>
                          <li>Power Off</li>
                          <li>Batteries Off</li>
                          <li>Secure Possessions In Boat Cupboards</li>
                          <li>Remove Trash From Boat</li>
                        </ul>
                    </Col>
                </Row>
            </div>
        </section>
        </>
      )
}
