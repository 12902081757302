import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import FloatingButtons from './FloatingButtons'

export default function GetVerified() {
  return (
    <>
    <FloatingButtons/>
    <section className='cs-bg-dark py-4 cs-section'>
      <div className='container'>
        <Row className='mb-4'>
        <Col> 
          <Row className='mt-4'>
            <Col>
              <h1 className='text-white'>BBR RENTER VERIFICATION PROCESS</h1>
              <h4 className='text-white'>Only required for the driver of the boat</h4>
              <p className='text-white'>The bass boats we rent have 250hp outboard motors, capable of speeds in excess of 70 miles per hour. 
              <br/>Our insurance underwriter and common sense requires that we verify our customers ability to safely operate the equipment.
              <br/>If you plan to rent one of our bass boats you will need to get verified and provide certain specific information.
              <br/>If you plan to rent soon we recommend you get verified now!
              <br/>Basically if you are a fisherman and you have experience operating a 20ft bass boat, you will likely have no problem renting a boat from us.
              <br/>Once you are verified, you may rent a boat from BBR bass boat rentals whenever there is a boat available.
              <br/>But don't stress ... Even if you have no experience operating these boats, we can definitely get you set up with a qualified boat captain and fishing guide, just drop us a line 936-276-7787</p>
            <Link to='/auth/signup-renter' className='btn btn-light'>Get Verified</Link>
            </Col>
          </Row>
        </Col>
        </Row>
      </div>
    </section>

    
    </>
  )
}
